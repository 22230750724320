@font-face {font-family: "Avenir"; src: url("../fonts/Avenir-Heavy.ttf") format("truetype"); font-weight: 900;}
@font-face {font-family: "Avenir"; src: url("../fonts/Avenir-Medium.ttf") format("truetype"); font-weight: 500;}
@font-face {font-family: "Avenir"; src: url("../fonts/Avenir-Light.ttf") format("truetype"); font-weight: 350;}


body{
  overflow-x: hidden;
}
.div_on_hover  .div_on_hover_close img{background-color: transparent;width: 20px;position: absolute;right: 10px;top: 10px;}

/* MediaResources Banner */
.media-resources-card {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center; 
  box-sizing: border-box;
  padding: 20px;
  overflow-x: hidden;
  text-align: center;
  margin: 30px auto;
}

.media-resources-image {
  max-width: 55%; 
  height: auto;
  margin-right: 20px; 
}

.media-resources-heading {
  color: #000;
  font-family: Avenir;
  font-size: 46.84px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

/* Fintech News css */
.fintech-header {
  text-align: center;
  margin-bottom: 20px;
}

.fintech-header .fintech-title{
  color: #0069B5;
  text-align: center;
  font-family: Avenir;
  font-size: 51px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 60px auto 10px auto;
}

.fintech-header p {
  color: #000;
  text-align: center;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  max-width: 750px;
  margin: 0px auto 40px auto;
}

.fintech-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 40px;
  margin-bottom: 30px;
  position: relative; 
  z-index: 99999999 !important;
}

.fintech-card {
  cursor: pointer;
  background-color: #EAEEFA;
  border-bottom: 8px solid #1D234B;
  width: 318px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.fintech-card img {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
}

.card-content {
  padding: 15px;
  text-align: center;
}

.card-meta {
  display: flex;
  justify-content: center; 
  gap: 10px; 
  margin-bottom: 10px;
}

.card-date {
  font-size: 0.9rem;
  color: #000;
  text-align: center;
  font-family: Avenir;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px; /* 220% */
}

.card-source {
  font-size: 0.9rem;
  color: #0069B5;
  font-family: Avenir;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px;
}

.card-content p {
  color: #000;
  text-align: center;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px; /* 165% */
}


.svg_div_fintechNews {
  position: absolute;
  width: 100%;
  /* bottom: 10%; */
  left: 0;
  right: 0;
  z-index: -1; 
}

.svg_div_fintechNews svg {
  transform: scaleX(-1);
  width: 100%;
  overflow: hidden;
}


.fintech-card-slider{
  cursor: pointer;
  background-color: #EAEEFA;
  border-bottom: 8px solid #1D234B;
  width: 318px !important;
  height: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}


.fintech-card-slider img {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.fintech-slider .slick-prev:before,
.fintech-slider .slick-next:before {
  content: none; 
}

.slick-dots {
  display: none !important; 
}

.fintech-slider .slick-prev,
.fintech-slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.fintech-slider .slick-prev {
  left: 10px; 
}

.fintech-slider .slick-next {
  right: 20px;
}

.fintech-slider .slick-prev img,
.fintech-slider .slick-next img {
  width: 30px; 
  height: 30px;
}

/* Press Release */
.press-release-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  text-align: center;
}

.press-release-title {
  color: #207CBE;
  text-align: center;
  font-family: Avenir;
  font-size: 51px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
  margin: 0 auto 20px;
}

.press-release-description {
  color: #000;
  text-align: center;
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  max-width: 700px;
  margin: 0 auto;
}

.press-release-grid {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 40px;
  position: relative;
  margin-top: 20px;
}

.press-release-item {
  text-align: center;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center;
  /* padding-bottom: 20px;  */
}

.press-release-item p {
  color: #000;
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  line-height: 1.4;
  margin-bottom: 20px;
}

.view-button {
  background: linear-gradient(0deg, #00248e 3.13%, #0091ff 78.38%);
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: auto; 
}

.view-button:hover {
  background-color: #005bb5;
}

.vertical-line {
  width: 4px;
  height: auto;
  background-color: #555;
  opacity: 0.4;
  margin-top: 30px;
  align-self: stretch; 
}

.press-release-email-subscription{
  margin: 70px auto 25px;
}
.press-release-email-subscription label {
  color: #000;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
}


.press-release-email-subscription{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0; 
}

.press-release-email-subscription input {
  padding: 16.5px 20px;
  border-radius: 30px 0 0 30px !important; 
  border: none;
  border-right: none; 
  width: 300px;
  outline: none;
  font-size: 16px;
  background-color: #f9f9f9;  
  box-shadow: 0 -0.5vw 1vw rgba(0, 0, 0, 0.05), 0 0.5vw 1vw rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

.subscribe-button {
  background: linear-gradient(0deg, #00248e 7.68%, #0091ff 82.02%);
  border: none;
  padding: 15px 25px;
  border-radius: 0 30px 30px 0; 
  cursor: pointer;
  color: #FFF;
  font-family: Avenir;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}



/*  */

.brand-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100%;
  width: 95%;
  /* padding: 20px; */
  margin: 0px auto 25px;
}

.brand-title {
  color: #000;
  font-family: Avenir;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px auto 20px;
}

.brand-main-title {
  color: #0069B5;
  text-align: center;
  font-family: Avenir;
  font-size: 51px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px auto 25px;
}

.brand-description {
  color: #000;
  text-align: center;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  margin-bottom: 20px;
  max-width: 1050px; 
  margin: 0px auto 30px;
}

.brand-button {
  background: linear-gradient(0deg, #00248e 3.12%, #0091ff 78.38%);
  border: none;
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  padding: 12px 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.brand-button:hover {
    background: linear-gradient(0deg, #0056b3 3.12%, #007bff 78.38%);
}

/* Press Resources */
.press-resources-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 80px;
  background-color: #0e1d4d;
  color: white;
  position: relative;
  overflow: hidden;
}

.press-title {
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-size: 51px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px auto 40px;
}

.resources-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative; 
  z-index: 1111111 !important;
}

.resource-item {
  text-align: center;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resource-item-title {
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.resource-item p {
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.resource-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.download-button {
  background: linear-gradient(0deg, #00248e 3.13%, #0091ff 78.38%);
  border: none;
  color: #FFF;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  border-radius: 50px;
  cursor: pointer;
  padding: 10px 50px;
  margin-top: 20px;
}

.download-button:hover {
  background-color: #005bb5;
}

.press-resources-vertical-line {
  width: 2px;
  height: 300px;
  background-color: #FFF;
  opacity: 0.4;
}

.svg_div_Pressure-resources {
  position: absolute; 
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; 
  overflow: hidden;
}

.svg_div_Pressure-resources svg {
  transform: scaleX(-1); 
  width: 100%;
  height: auto;
}



/* TermsAndConditions.css */
.terms-container {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
  margin: 20px 0;
  overflow: hidden;
}

.terms-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}

.terms-flex {
  display: flex;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  max-width: 1200px; 
}

.terms-image {
  width: 50%;
  height: auto;
  margin-right: 20px; 
}

.terms-header .terms-title {
  color: #000;
  font-family: Avenir;
  font-size: 55px; 
  font-weight: 800;
  margin: 0;
  text-align: left; 
}

.terms-content {
  text-align: justify;
  padding: 20px 90px;
}
.section-title {
  color: #000;
  font-family: Avenir;
  font-size: 26px !important;
  font-weight: 900 !important;
  margin: 0 auto;

}

.section-description p,
.section-description ol li {
  color: #000;
  font-family: Avenir;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.section-description ol li {
  margin-bottom: 15px;
}


/* Privacy Policy Styles */

.privacy-policy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  margin: 20px 0;
  overflow: hidden;
}

.privacy-policy-header {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-bottom: 20px;
  width: 100%; 
}

.privacy-policy-image {
  width: 45%; 
  max-width: 500px;
  height: auto; 
  margin-right: 20px;
}

.privacy-policy-title {
  color: #000;
  font-family: Avenir;
  font-size: 55px; 
  font-weight: 800;
  margin: 0;
  text-align: left;
}

.privacy-policy-content {
  text-align: justify;
  padding: 20px 70px; 
}

.privacy-section-title {
  color: #000;
  font-family: Avenir;
  font-size: 26px !important;
  font-weight: 900;
}

.privacy-section-description p,
.privacy-section-description ul li {
  color: #000;
  font-family: Avenir;
  font-size: 18px !important;
  font-weight: 400;
}

.privacy-section-description p {
  margin-bottom: -5px !important;
}

.privacy-section-description ul {
  list-style-type: disc;
  padding-left: 40px;
}

.privacy-section-description ul li {
  margin-bottom: 10px;
}

/* ContactUsImage.css */

.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  overflow: hidden; 
  padding: 20px;
  box-sizing: border-box;
}

.contact-us-image {
  max-width: 100%; 
  height: auto; 
  object-fit: contain; 
}

/* ContactUsImage.css */
.contact-info-container {
  text-align: center;
  padding: 20px;
}

.contact-title {
  color: #000;
  font-family: Avenir;
  font-size: 2.75rem; 
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;
}

.contact-details {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 10%;
  max-width: 100%; 
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 10px; 
}

.contact-icon {
  max-width: 100%;
  height: auto;
  max-height: 100px;
}

.contact-text {
  color: #000;
  font-family: Avenir;
  font-size: 2.75rem; 
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.contact-details::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(50%);
  width: 1px;
  height: 80%;
  background-color: black;
  z-index: -1;
}

.contact-link {
  text-decoration: none !important;
  /* color: inherit;  */
}

.contact-link:hover {
  text-decoration: underline;
  color: #000;
}



.contact-form-wrapper {
  position: relative;
  overflow: hidden; 
}


.contact-form-container {
  max-width: 600px;
  margin: 30px auto ;
  padding: 40px 50px 30px;
  background-color: rgba(223, 224, 225, 0.8);
  /* background-color: #DFE0E1; */
  border-radius: 30px;
  text-align: center;
  position: relative;
  z-index: 111 !important;
}

.form-title {
  color: #000;
  font-family: Avenir;
  font-size: 44.08px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 10px;
}

.form-subtitle {
  color: #000;
  font-family: Avenir;
  font-size: 38.57px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  /* margin-bottom: 30px; */
  margin:0px auto 30px;

}

.contact-form-header{
  position: relative;
  z-index: 9 !important;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.form-input, .form-textarea {
  position: relative; 
  width: 100%;
  padding: 15px;
  margin-bottom: 20px; 
  border: none;
  border-radius: 30px;
  background-color: #e0e0e0;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  background: linear-gradient(90deg, #FFF 12%, #EAECF2 100%);
  filter: drop-shadow(2px 4px 2px rgba(21, 75, 124, 0.60));
  color: #696A6D;
  font-family: Avenir;
  font-weight: 500;
}

.error-text {
  color: red; 
  font-size: 0.875rem;
  margin-top: -1rem; 
  height: 1rem;
  text-align: left;
  display: block;
  width: 100%; 
  padding-left: 15px; 
  margin-bottom: 10px;
}

.submission-message {
  font-weight: bold; 
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}


.form-textarea {
  height: 120px;
  resize: none;
  
}

.form-input:focus,
.form-textarea:focus {
  border-color: #24A0D0;
  outline: none; 
  box-shadow: 0 0 5px rgba(36,160,208,0.5);
}

.form-button {
  background:  linear-gradient(0deg, #00248E 3.12%, #0091FF 78.38%);;
  color: #fff;
  padding: 15px 55px;
  border: none;
  margin-top: 10px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.form-button:hover {
  background-color: #0056b3;
}


.svg_div_contact-form{
  position: absolute;
  width: 100%;
  bottom:26%;
  left: 0;
  right: 0;
  z-index: 2;}

.svg_div_contact-form svg{transform: scaleX(-1);}





/* Blog Banner */
.blogbanner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.blogbanner-content {
  display: flex;
  align-items: center;
}

.blogbanner-image {
  /* max-width: 100%; */
  max-width: 40%;
  height: auto;
  display: block;
  margin-right: 20px; 
  z-index:111111111 !important;
}

.blogbanner-title {
  color: #000;
  font-family: Avenir;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0; 
  z-index: 111111111 !important;
}


.svg_div_blogbanner{
  position: absolute;
  width: 100%;
  /* bottom:0; */
  bottom:-16px;
  left: 0;
  right: 0;
  z-index: 2;}

.svg_div_blogbanner svg{transform: scaleX(-1);}

/* BlogContent Styles */
.blogcontent-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 90vw;
  gap: 30px;
  justify-content: center;
  margin: 60px auto 20px;
}


.blogs-title {
  font-size: 3.5rem;
  color: #000;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 20px 0;
}

.blog-card {
  background: #EAEEFA;
  overflow: hidden;
  width: 318px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s ease;
  border-bottom: 8px solid #1D234B;
  border-radius: 0 50px 0 50px;
  background-color: #1D234B;
}

.blog-card:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.blog-card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.blog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.blog-card-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: flex-start !important; 
  padding: 0px 20px;
}

.blog-card-content h2 {
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  /* line-height: 33px;  */
}

.blog-card-footer {
  padding: 10px;
  text-align: right;
}
.feed_btn:hover{opacity: 0.8;}
.blog-card-readmore {
  color: #fff;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.prev-page,
.next-page {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.prev-page img {
  width: 15px !important;
  margin-right: 30px;
}

.next-page img {
  width: 15px !important;
  margin-left: 30px;
}

.current-page {
  color: #000;
  font-family: Avenir;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 82px;
}

.prev-page:disabled img,
.next-page:disabled img {
  opacity: 0.5; 
  cursor: not-allowed;
}


.blog-detail {
  padding: 20px;
  margin: 0 auto;
  position: relative;
}

.blog-detail-date {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin: 10px 0;
}


.blog-detail-image {
  width: 100%;
  height: auto; 
  max-width: 100%; 
  display: block;
  object-fit: cover;
}

.back-button {
  cursor: pointer;
  position: absolute;
  left: 20px;
  z-index: 1000;
  width: 40px;
}

.blog-detail-content p {
  font-size: 1rem;
  line-height: 1.6;
  font-family: Avenir;
  text-align: left;

}
.blog-detail-content img {
  max-width: 100%; 
  height: auto; 
  display: block;
  margin: 0 auto;
}

.blogcontent-wrapper {
  position: relative;
}

.blog-detail-content-wrapper{
  max-width: 900px;
  margin: 0 auto;
  font-family: Avenir !important;
  
}

.blog-detail-title {
  font-family: Avenir;
  font-size: 28px;
  font-weight: 800;
  color: #000;
  margin: 0px auto 20px;
  text-align: left;

}


.loader {
  border: 8px solid #f3f3f3; 
  border-top: 8px solid #3498db; 
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@media (min-width: 1920px) {

    
  .contact-form-container {max-width: 550px;padding: 85px 75px 30px;}
  .form-title { font-size: 60px;}
  .form-subtitle {font-size: 45px;}
  .form-input, .form-textarea {font-size: 14px;}
  .svg_div_contact-form{bottom: 3%;}
  
  .terms-content {padding: 20px 5px;}
  .section-title{font-size: 45px;}
  .section-description p,
  .section-description ol li{font-size: 28px;}
  .terms-title{font-size: 70px;}



}

@media (max-width: 1800px) {
  .blogcontent-container{
    max-width: 70vw;
  }
}
@media (max-width: 1600px) {
  .blogcontent-container{
    max-width: 75vw;
  }
}
@media (min-width: 1350px) {
  .fintech-card {
    width: calc(25% - 20px); 
  }
    
}
@media (max-width: 1350px) {


  .contact-form-container {max-width: 550px; padding: 40px 50px 30px;}
  .form-title { font-size: 40px;}
  .form-subtitle {font-size: 38px;}


  .terms-content {padding: 20px 35px;}
  .section-title{font-size: 45px;}
  .section-description p,
  .section-description ol li{font-size: 24px;}
  .terms-title{font-size: 70px;}


  .contact-title{font-size: 32px;}
  .contact-icon { max-height: 100px;}
  .contact-title{font-size: 34px;}
  .contact-details {padding: 0 8%;}

}

@media (max-width: 1250px) {

  .terms-image {width: 45%; }
  .terms-header .terms-title {font-size: 50px; }
  .section-title{font-size: 40px;}
  .section-description p,
  .section-description ol li {font-size: 20px !important;}
  .terms-title{font-size: 60px;}


  .privacy-policy-image {width: 35%;}
  .privacy-policy-title {font-size: 54px; }
  .privacy-section-title {font-size: 22px; }
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 20px; }

  .contact-form-container {max-width: 550px;padding:40px 50px 30px;}
  .form-title { font-size: 40px;}
  .form-subtitle {font-size: 36px;}



  .contact-title{font-size: 30px;}
  .contact-icon { max-height: 100px;}
  .contact-title{font-size: 32px;}
  .contact-details {padding: 0 8%;}
}

@media (max-width: 1150px) {

  .terms-image {width: 45%; }
  .terms-title {font-size: 50px; }
  .terms-title{font-size: 55px;}
  .section-title{font-size: 30px;}
  .section-description p,
  .section-description ol li{font-size: 18px !important;}

 

  .privacy-policy-image {width: 30%;}
  .privacy-policy-title {font-size: 50px; }
  .privacy-section-title {font-size: 20px; }
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 18px; }


  .contact-title{font-size: 30px;}
  .contact-icon { max-height: 80px;}
  .contact-title{font-size: 30px;}
  .contact-details {padding: 0 8%;}
}

@media (max-width: 1000px) {
  .terms-image {width: 40%;}
  .terms-title{font-size: 40px;}
  .terms-content {padding: 20px 60px;}
  .section-title{font-size: 28px;}
  .section-description p,
  .section-description ol li{font-size: 16px !important;}


  .privacy-policy-image {width: 25%;}
  .privacy-policy-title {font-size: 46px; }
  .privacy-section-title {font-size: 18px; }
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 18px; }


  .contact-icon { max-height: 80px;}
  .contact-title{font-size: 38px;}
  .contact-details {padding: 0 8%;}
  .contact-text{font-size: 24px;}

  .contact-form-container {max-width: 550px;padding: 40px 50px 30px;}
  .form-title { font-size: 38px;}
  .form-subtitle {font-size: 34px;}

  .blog-detail-content-wrapper{
    max-width: 650px;
  }

}

@media (max-width: 850px) {

  .terms-image {width: 45%;}
  .terms-header .terms-title {font-size: 40px; }
  .terms-content {padding: 20px 60px;}
  .section-title{font-size: 24px !important;}
  .section-description p,
  .section-description ol li {font-size: 14px !important;}

  .privacy-policy-image {width: 30%;}
  .privacy-policy-title {font-size: 40px; }
  .privacy-section-title {font-size: 16px; }
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 14px; }


  .contact-title{font-size: 36px;}
  .contact-icon { max-height: 70px;}
  .contact-details {padding: 0 6%;}
  .contact-text{font-size: 24px;}

  .contact-form-container {max-width: 450px;padding: 40px 50px 30px;}
  .form-title { font-size: 36px;}
  .form-subtitle {font-size: 30px;}
  .svg_div_contact-form{bottom: 45%;}

  .blog-detail-content-wrapper{
    max-width: 550px;
  }


  /* Responsive Design */
  .fintech-cards-container {
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto; 
  }

  .fintech-cards-container {
    width: 100%;
  }
}



@media (max-width:768px) {
  .media-resources-card {
    flex-direction: column;
    align-items: flex-end; 
    text-align: right; 
    margin: 20px auto;
  }

  .media-resources-image {
    max-width: 100%; 
    margin-right: 0;
    margin-bottom: 0; 
  }

  .media-resources-heading {
    font-size: 38px; 
    margin: -80px 0 10px 0; 
    line-height: 1; 
  }

  .fintech-header {padding: 0px 25px;}
  .fintech-header .fintech-title {font-size: 30px; margin-bottom: 20px;}
  .fintech-header p {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 19px;
  }
  .card-content p {font-size: 20px;line-height: 24px;}
  .fintech-card-slider{margin-bottom: 30px;}
  .fintech-slider .slick-prev {left: 100px; }
  .fintech-slider .slick-next {right: 100px;}

  .press-release-grid {
    flex-direction: column;
    gap: 20px;
  }
  .vertical-line {width: 100%;height: 2px;}
  .email-subscription {flex-direction: column;}
  .press-release-email-subscription {
    flex-direction: row;
    justify-content: center; 
    align-items: center; 
    gap: 0;
  }

  .press-release-email-subscription input {
    width: 70%;
    padding: 13px 15px; 
    font-size: 14px; 
    border-radius: 30px 0 0 30px; 
  }
  .view-button{padding: 10px 40px;}
  .subscribe-button {
    width: 30%;
    padding: 12px 15px; 
    font-size: 14px; 
    border-radius: 0 30px 30px 0; 
  }
  .press-release-item p{font-size: 24px;}
  .brand-title{font-size: 20px;}
  .brand-main-title {font-size: 35px; }
  .brand-description {font-size: 18px;font-weight: 500;line-height: 19px;}
  .press-release-title {font-size: 30px;}
  .press-release-description {font-size: 18px;}

  .press-resources-container {  padding: 30px 20px;}
  .press-title {font-size: 28px;}
  .resource-item-title {font-size: 21px;  margin: 10px auto ;  }
  .resource-item p {font-size: 15px;}
  .download-button {font-size: 14px; padding: 10px 40px;margin: 15px auto;}


  .title_FintechNews {font-size: 37px;margin:25px 0px;}
  .description-fintechNews {font-size: 22px;max-width: 80%;}
  .Fintechnews_blocks {width: 85%;margin:40px auto}
  .block_data_cont_Fintechnews {border-radius: 40px 6px 6px 6px ;}
  .fintech-news-container {width: 90%; margin: 40px auto;}
  .border-indicator { margin-top: 10px; height: 5px;}


  .terms-image {width: 45%;}
  .terms-header .terms-title {font-size: 40px; }
  .terms-content {padding: 20px 40px;}
  .section-title{font-size: 24px !important;}
  .section-description p,
  .section-description ol li {font-size: 14px !important;}


  .privacy-policy-image {width: 45%;}
  .privacy-policy-title {font-size: 40px; }
  .privacy-policy-content {padding: 0px 40px;}
  .privacy-section-title {font-size: 18px !important; }
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 14px; }

  
  .contact-title{font-size: 34px;}
  .contact-icon { max-height: 70px;}
  .contact-details {padding: 0 6%;}
  .contact-text{font-size: 22px;}

  .contact-form-container {max-width: 400px;padding: 50px 60px 25px;}
  .form-title { font-size: 32px;}
  .form-subtitle {font-size: 28px;}
  .svg_div_contact-form{bottom: 50%;}

  .blogcontent-container {display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin: 20px; 
  }

  .blog-card {width: 100%; border-bottom: 6px solid #1D234B;}
  .blog-card-image {width: 100%;height: auto;}
  .blog-card-content {padding: 5px;}
  .blog-card-content h2 {font-size: 14px;}
  .blog-card-readmore {font-size: 14px;}
  .current-page {font-size: 24px;margin: 20px 20px;}
  .prev-page img { width: 10px !important;}
  .next-page img {width: 10px !important;}
  .back-button {left: 10px;width: 30px;}
  .blog-detail-content-wrapper{max-width: 450px;}
  .blog-detail-title {font-size: 20px;}

  .blogbanner-image {
    max-width: 60%;
  }

  
.blogs-title{
  font-size: 2rem;
}

.resources-grid {flex-direction: column;gap: 20px;}
.press-resources-vertical-line {width: 100%;height: 2px;}
.svg_div_Pressure-resources{
  top: 15%;
}
}


@media (max-width:650px) {
 .fintech-slider .slick-prev {left: 70px; }
 .fintech-slider .slick-next {right: 70px;}

  .contact-title{font-size: 34px;}
  .contact-icon { max-height: 70px;}
  .contact-details {padding: 0 6%;}
  .contact-text{font-size: 20px;}

  .contact-form-container {max-width: 350px;padding: 45px 45px 25px;}
  .form-title { font-size: 34px;}
  .form-subtitle {font-size: 22px;}
  .svg_div_contact-form{bottom: 50%;}
  .form-button {font: 16px;  padding: 15px 45px;}
  .form-input{padding: 10px; margin-bottom: 13px; }
  .form-textarea {padding: 10px; margin-bottom: 13px; border-radius: 10px;
  }

  .terms-image {width: 45%;}
  .terms-header .terms-title {font-size: 35px; }
  .terms-content {padding: 20px 40px;}
  .section-title{font-size: 25px !important;}
  .section-description p,
  .section-description ol li {font-size: 14px !important;}


}

@media (max-width: 550px) {
 .fintech-slider .slick-prev {left: 40px; }
 .fintech-slider .slick-next {right: 40px;}

    
    .terms-image {width: 45%;}
    .terms-header .terms-title {font-size: 25px; }
    .terms-content {padding: 20px 40px;}
    .section-title{font-size: 24px;}
    .section-description p,
    .section-description ol li {font-size: 14px !important;}

    .privacy-policy-image {width: 45%;}
    .privacy-policy-title {font-size: 35px; }
    .privacy-section-title {font-size: 18px !important; }
    .privacy-section-description p,
    .privacy-section-description ul li {font-size: 14px !important; }
  

    .contact-title{font-size: 34px;}
    .contact-icon { max-height: 60px;}
    .contact-text {font-size: 20px;}
    .contact-details {padding: 0 5%;}

    .contact-form-container {max-width: 350px;padding: 40px 45px 25px;}
    .form-title { font-size: 34px;}
    .form-subtitle {font-size: 18px;}
    .svg_div_contact-form{bottom: 50%;}
    .form-button {font: 16px;  padding: 15px 45px;}
    .form-input{padding: 10px; margin-bottom: 12px; }
    .form-textarea {padding: 10px; margin-bottom: 12px; border-radius: 10px;
    }

    .blog-detail-content-wrapper{
      max-width: 350px;
    }
  }

@media (max-width: 470px) {
  .media-resources-heading {
    margin: -60px 0 10px 0; 
  }


  .fintech-slider .slick-prev {left: 10px; }
  .fintech-slider .slick-next {right: 20px;}


  .press-release-item p{font-size: 20px;}
  
  .PressResources-cardsContainer {flex-direction: column;gap: 35px;}
  .PressResources-card {margin-bottom: 35px;}
  .PressResources-card:not(:last-child)::after {content: none;}
  .PressResources-header {font-size: 32px;}
  .PressResources-title {font-size: 26px;}
  .PressResources-description {font-size: 18px;}
  .PressResources-button {padding: 8px 40px;font-size: 16px;}


  .terms-image {width: 50%;}
  .terms-header .terms-title {font-size: 22px; }
  .terms-content {padding: 0px 30px;}
  .section-title{font-size: 24px;}
  .section-description p,
  .section-description ol li {font-size: 13px !important;}

  .privacy-policy-image {width: 50%;}
  .privacy-policy-title {font-size: 30px; }
  .privacy-section-title {font-size: 18px !important; }
  .privacy-policy-content {padding: 0px 30px;}
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 13px !important; }
 

  .contact-title{font-size: 30px;}
  .contact-icon { max-height: 50px;}
  .contact-text {font-size: 18px;}
  .contact-details {padding: 0 4%;}

  .contact-form-container {max-width: 270px;padding: 40px 45px 25px;}
  .form-title { font-size: 30px;}
  .form-subtitle {font-size: 16px;}
  .svg_div_contact-form{bottom: 65%;}
  .form-button {font: 14px;  padding: 10px 45px;}

  .form-input{padding: 10px; margin-bottom: 10px; }
  .form-textarea {padding: 10px; margin-bottom: 10px; border-radius: 10px;
  }

  .blog-detail-content-wrapper{
    max-width: 350px;
  }

  .blogbanner-image {
    max-width: 50%; 
  }
  .svg_div_blogbanner{
    bottom:25px;
  }
}

@media (max-width: 400px) {
  .fintech-header {padding: 0px 25px;}
  .fintech-header .fintech-title{font-size: 24px;}
  .fintech-header p {font-size: 18px;}
 .fintech-slider .slick-prev {left: 10px; }
 .fintech-slider .slick-next {right: 20px;}
  .fintech-card-slider{width: 250px !important;height:auto;margin-bottom: 20px;}
  
  
  .brand-title{font-size: 18px;}
  .brand-main-title {font-size: 30px;}
  .brand-description {font-size: 16px;}
  .press-release-title {font-size: 25px;}
  .press-release-description {font-size: 16px;}
  .press-release-item p{font-size: 20px; font-weight: bold !important;}
  .brand-button {font-size: 14px;   padding: 10px 30px;  }

  
  .privacy-policy-image {width:50%;}
  .privacy-policy-title {font-size: 25px; }
  .privacy-section-title {font-size: 18px !important; }
  .privacy-policy-content {padding: 0px 25px;}
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 14px; }
 


  .contact-title{font-size: 26px;}
  .contact-icon { max-height: 35px;}
  .contact-text {font-size: 16px;}
  .contact-details {padding: 0 3%;}


  .contact-form-container {max-width: 240px;padding: 40px 40px 20px;}
  .form-title { font-size: 26px;}
  .form-subtitle {font-size: 15px;}
  .svg_div_contact-form{bottom: 65%;}
  .form-button {font: 14px;  padding: 10px 45px;}

  .form-input{padding: 10px; margin-bottom: 10px; }
  .form-textarea {padding: 10px; margin-bottom: 10px; border-radius: 10px;
  }


  .blog-detail-content-wrapper{
    max-width: 250px;
  }
 
}


@media (max-width:320px) {
  .media-resources-heading {font-size: 20px;margin: -35px 0 10px 0; }

  .fintech-header {padding: 0px 25px;}
  .fintech-header .fintech-title{font-size: 20px;}
  .fintech-header p {font-size: 16px;}
  .card-content p {font-size: 16px;}
 .fintech-slider .slick-prev {left: 10px; }
 .fintech-slider .slick-next {right: 20px;}
  .fintech-card-slider{width: 220px !important;}

  .subscribe-button {font-size: 12px;}
  .view-button {padding: 7px 30px;}
  .press-release-email-subscription label{font-size: 14px;}
  .press-release-item p{font-size: 18px;}
  .brand-button {font-size: 12px;   padding: 8px 20px;  }

  .brand-title{font-size: 16px;}
  .brand-main-title {font-size: 25px;}
  .brand-description {font-size: 16px;}
  .press-release-title {font-size: 25px;}
  .press-release-description {font-size: 16px;}

  .press-resources-container {  padding: 30px 20px;}
  .press-title {font-size: 26px;}
  .resource-item-title {font-size: 18px;  margin: 10px auto ;  }
  .resource-item p {font-size: 14px;}
  .download-button {font-size: 12px; padding: 8px 30px;margin: 15px auto;}


  .terms-image {width: 50%;}
  .terms-header .terms-title {font-size: 18px; }
  .terms-content {padding: 0px 20px;}
  .section-title{font-size: 18px !important; }
  .section-description p,
  .section-description ol li {font-size: 12px !important;}

  .privacy-policy-image {width: 45%;}
  .privacy-policy-title {font-size: 20px; }
  .privacy-section-title {font-size: 16px !important; }
  .privacy-policy-content {padding: 0px 20px;}
  .privacy-section-description p,
  .privacy-section-description ul li {font-size: 12px; }
 

  .contact-title {font-size: 24px;}
  .contact-icon { max-height: 35px;}
  .contact-text {font-size: 14px;}
  .contact-details {padding: 0 2%;}


  .contact-icon { max-height: 35px;}


  .contact-form-container {max-width: 200px;padding: 30px 40px 20px;}
  .form-title { font-size: 24px;}
  .form-subtitle {font-size: 14px;}
  .svg_div_contact-form{bottom: 65%;}
  .form-button {font: 14px;  padding: 10px 45px;}
  .form-input{padding: 10px; margin-bottom: 10px; }
  .form-textarea {padding: 10px; margin-bottom: 10px; border-radius: 10px;
  }


  .blog-detail-content-wrapper{
    max-width: 200px;
  }
 
}

