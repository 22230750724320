/* myfont.css */
@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir-Heavy.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir-Light.ttf') format('truetype');
    font-weight: 350;
}

.header_main_cont {
    position: relative;
}

.header_main_cont_div {
    padding: 25px;
    background-color: transparent;
    position: absolute;
    top: 0px;
    width: calc(100% - 60px);
    z-index: 99;
}

.header_main {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
}

.header_btn>ul,
.menu_cont>ul {
    display: flex;
    padding: 0;
}

.header_btn ul li,
.menu_cont ul li {
    list-style: none;
}

.header_btn ul li a,
.menu_cont ul li a {
    text-decoration: none;
    color: white;
}

.header_btn ul li {
    background-color: #0069B5;
    padding: 5px 15px;
    margin: 0 5px;
    border: 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_cont>ul>li {
    margin: 0 16px;
    position: relative;
}

.menu_cont>ul>li::after {
    content: "";
    width: 100%;
    height: 2px;
    color: #24A0D0;
    bottom: -10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    background-color: #24A0D0;
}

.menu_cont>ul>li::before {
    content: "";
    width: 20px;
    color: #24A0D0;
    bottom: -23px;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    background-image: url("../images//header_arrow.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
}

.header_btn ul li a {
    color: #FFF;
    font-family: Avenir;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.menu_cont>ul>li:hover,
.menu_cont>ul>li a:hover {
    color: rgb(36, 160, 208) !important;
    cursor: pointer !important;
}

.menu_cont .level_two_menu li a:hover {
    color: white;
}

.menu_cont .level_two_menu li a {
    font-size: 13px;
}

.level_two_menu>li {}

.menu_cont>ul>li a {
    font-size: 22px;
    /* text-transform: capitalize; */
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.logo_cont img {
    width: 90%;
    margin: 0 auto;
}

.logo_cont a {
    display: block;
    width: 90%;
}

.mobile_menu_div {
    display: none;
}


/* footer css */
.svg_div_footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    opacity: 0.8;
    z-index: 4;
}

.ls_footer_con a,
.footer_sec_div li a,
.footer_sec_div,
.footer_sec_cont,
.last_footer_section,
.footer_sec_cont {
    z-index: 8;
    position: relative;
}

.footer_main_container {
    background-color: #1D234B;
    position: relative;
    bottom: 0;
    width: 100%;
    color: white;
    position: relative;
    overflow: hidden;
}

.footer_sec_cont {
    padding: 250px 70px 30px 70px;
    text-align: left;
}

.footer_sec_div ul {
    padding: 0;
    margin: 0;
}

.footer_sec_cont p {
    padding: 0;
    margin: 0;
}

.footer_sec_div ul li,
.footer_sec_div a {
    text-decoration: none;
    list-style: none;
}

.footer_sec_div li a b,
.footer_sec_div b {
    font-size: 20px;
    /* line-height: 40px; */
}

.footer_sec_div li a,
.footer_sec_div {
    color: white;
    font-size: 15px;
    line-height: 45px;
    font-family: Avenir;
    font-style: normal;
}

.footer_sec_div .address_footer p:nth-child(2) {
    line-height: 30px;
}

.footer_sec_cont {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.first_foot {
    width: 50%;
}

.sec_foot,
.third_foot {
    width: 50%;
}

.social_logo_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social_logo_cont a {
    display: flex;
    margin: 0 10px;
    align-items: center;
}

.social_logo_cont p {
    margin: 0 0 0 30px;
    font-size: 23px;
    color: #FFF;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

.social_logo_cont img {
    width: 31px;
    object-fit: cover;
}

.ls_footer_con {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px 0 70px;
    align-items: center;
}

.ls_footer_con a {
    text-decoration: none;
    color: #FFF;
    font-family: Avenir;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.twodiv_combine {
    display: flex;
    width: 50%;
}

.desk_banner {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.mob_banner {
    display: none;
}

.text_banner {
    position: absolute;
    top: 0%;
    left: 8%;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.text_banner p {
    margin: 0;
    text-align: left;
}

.first_txt {
    color: #24A0D0;
    font-family: Avenir;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sec_txt {
    color: #FFF;
    font-family: Avenir;
    font-size: 70px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pragram_two_div {
    max-width: 1110px;
    width: 90%;
    margin: 90px auto 0 auto;
    text-align: center;
    z-index: 5;
    position: relative;
}

.pragram_two_div p {
    color: #000;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.blk_readmore_ {
    background-color: #0069B5;
    text-align: right;
    color: #FFF;
    font-family: Avenir;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 30px;
}

.block_data_cont {
    width: 35%;
    background-color: #1D234B;
    border-radius: 100px 30px 30px 30px;
    border: 0px solid transparent;
    overflow: hidden;
    box-shadow: 11px 12px 10px darkgray;
    z-index: 4;
}

.leding_and_disti_blocks a {
    text-decoration: none;
    color: white;
    text-align: center;
    display: block;
}

.blk_tit {
    color: #FFF;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 0px;
}

.leding_and_disti_blocks {
    max-width: 920px;
    width: 80%;
    margin: 100px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.paragram_and_blocks_cont {
    position: relative;
}

.blk_img {
    width: 100%;
    margin: 0 auto;
}

.blk_img img {
    height: 120px;
    object-fit: cover;
    max-width: 100%;
}

.title_Oursubsidiaries {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 120px 0;
}

.blk_readmore_Oursubsidiaries {
    color: #FFF;
    font-family: Avenir;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 26px;
    text-align: right;
    background-color: #1C2333;
}

.blk_tit_Oursubsidiaries {
    color: #000;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 10px;
}

.block_data_cont_Oursubsidiaries a {
    display: block;
    text-decoration: none;
}

.block_data_cont_Oursubsidiaries {
    box-shadow: 11px 12px 10px darkgray;
    border: 2px solid #1C2333;
    border-radius: 100px 20px 20px 20px;
    width: 27%;
    overflow: hidden;
    z-index: 5;
    background-color: white;
}

.Oursubsidiaries_blocks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 68%;
    margin: 50px auto 100px auto;
    z-index: 5;
    position: relative;
    max-width: 1440px;
    gap: 50px;
}

.blk_img_Oursubsidiaries img {
    width: 91px;
    height: 91px;
    object-fit: cover;
}

.blk_img_Oursubsidiaries {
    padding: 20px;
}

.NIYOGINfeed_imag_and_slider_div img {
    width: 100%;
    z-index: 1;
    margin-bottom: -10%;
}

.block_feed_div {
    min-height: 370px;
    max-height: 370px;
    box-shadow: 11px 12px 10px rgba(0, 0, 0, 0.301);
    border: 2px solid #1C2333;
    border-radius: 100px 20px 20px 20px;
    overflow: hidden;
    background-color: #FFF;
}

.slick-initialized .slick-slide>div {
    padding: 0 20px;
}

.block_feed_div a {
    display: flex;
    text-decoration: none;
    padding: 40px 30px 30px 30px;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}

.feed_btn {
    text-align: center;
    width: fit-content;
    /* padding: 8px 22px; */
    display: block;
    /* float: right; */
    background-color: #1C2333;
    color: white;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.feed_tit {
    color: #0069B5;
    font-family: Avenir;
    font-size: 21px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.feed_paragaph {
    max-height: 60px;
    overflow: hidden;
    color: #000;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cros_close_btn {
    position: absolute;
    right: 30px;
    cursor: pointer;
}

.NIYOGINfeed_imag_and_slider_div {
    position: relative;
}

.block_slider_feed {
    position: absolute;
    bottom: -10%;
    z-index: 6;
    width: 85%;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 1110px;
}

.read_center {
    display: flex;
    justify-content: end; 
    align-items: center; 
}

.slider_part_class .slick-prev:before {
    position: absolute;
    content: "";
    background-image: url("../images/black_prev_arrow.png");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    left: -100%;
    top: -80%;
}

/* .acord_cont .Mui-expanded{} */
.slider_part_class .slick-next:before {
    position: absolute;
    content: "";
    background-image: url("../images/black_next_arrow.png");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    right: -100%;
    top: -80%;
}

.block_slider_feed .slick-prev::before {
    position: absolute;
    content: "";
    background-image: url("../images/prevIcon.png");
    background-repeat: no-repeat;
    width: 20px;
    height: 44px;
    background-position: center;
    background-size: cover;
    left: 5px;
}

.block_slider_feed .slick-next::before {
    position: absolute;
    content: "";
    background-image: url("../images/nextIcon.png");
    background-repeat: no-repeat;
    width: 20px;
    height: 44px;
    background-position: center;
    background-size: cover;
    right: 5px;
}

.niyo_news_img_div {
    width: 30%;
}

.niyo_news_all_img {
    display: flex;
    justify-content: space-between;
}

.niyo_text .niyo_text_p_tit {
    color: #000;
    font-family: Avenir;
    font-size: 33px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.niyogin_text_and_imgs {
    width: 80%;
    margin: 10px auto 70px auto;
    max-width: 1440px;
}

.niyo_news_img_div img {
    height: 100px;
    object-fit: contain;
    width: 100%;
    min-height: 100px;
}

.circle_numbers {
    color: #0069CD;
    font-family: Avenir;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ExploreApi_main_cont a {
    text-decoration: none;
}

.InvestorBusinessValue_main_container {
    position: relative;
    z-index: 5;
}

.circle_title {
    color: #231F20;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.circle_inside_three_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    width: 75%;
    margin: 0px auto;
    max-width: 750px;
}

.circle_div_with_img img {
    width: 100%;
}

.data_circl_div p {
    margin: 0;
}

.circle_div_with_img {
    position: relative;
    width: 33%;
    z-index: 3;
}

.data_circl_div {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 30%;
    width: 100%;
}

.circle_div_with_img:nth-child(2) {
    z-index: 5;
}

.circle_div_with_img:nth-child(1) {
    margin-right: -4%;
}

.circle_div_with_img:nth-child(3) {
    margin-left: -4%
}

.circle_div_with_img:nth-child(2) .data_circl_div p {
    color: white;
}

.circle_data_cont {
    margin: 150px auto 100px auto;
}

.part_title {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.part_logo_img_div,
.part_logo_img_div img {
    width: 100%;
}

.slider_part_class {
    width: 100%;
    margin: 0 auto;
}

.silderr_partner_logo {
    padding: 0 0 40px 0;
    margin: 50px auto 100px auto;
    width: 85%;
    max-width: 1440px;
}

.home_svg {
    position: absolute;
    width: 100%;
    bottom: 0px;
    transform: scaleX(1);
}

.home_svg svg {
    width: 100%;
}

.home_all_data {
    position: relative;
    overflow: hidden;
}

.svg_div {
    position: absolute;
    width: 100%;
    bottom: -100px;
    left: 0;
    right: 0;
    z-index: 2;
}

.Oursubsidiaries_main {
    position: relative;
}

.svg_div_our_subsidiaries {
    position: absolute;
    width: 100%;
    bottom: -16%;
    left: 0;
    right: 0;
    z-index: 2;
}

.svg_div_our_subsidiaries svg {
    transform: scaleX(-1);
}

.svg_div_our_subsidiaries_2 {
    display: none;
}

.abt_text_tit_p h2 {
    color: #000;
    font-family: Avenir;
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    margin: 0;
    line-height: normal;
}

.abt_text_tit_p p {
    color: #000;
    margin: 0;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.image_txt_div_container {
    width: 85%;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    gap: 100px;
    max-width: 1200px;
}

.image_txt_div:nth-child(1) {
    margin-bottom: 100px;
    position: relative;
}

.svg_animation1 {
    position: absolute;
    width: 100%;
    bottom: -100%;
    transform: scaleX(-1);
    z-index: 2;
}

.svg_animation1 img {
    width: 100%;
}

.image_txt_div {
    display: flex;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    justify-content: space-between;
}

.img_abt_vec img {
    width: 100%;
}

.abt_text_tit_p {
    width: 43%;
    text-align: left;
}

.img_abt_vec {
    width: 50%;
    z-index: 3;
}

.image_txt_div:nth-child(2) {
    flex-direction: row-reverse;
}

.header-other .header_main_cont_div {
    position: unset;
    background-color: #1D234B;
}

.circ_abt_img_txt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.img_and_tex_connt_abt {
    width: 48%;
    position: relative;
}

.cir_about_img img {
    width: 100%;
}

.text_tit_p_abt {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 70%;
}

.text_tit_p_abt h2 {
    color: #000;
    font-family: Avenir;
    font-size: 33px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0 0 20px 0;
}

.text_tit_p_abt p {
    color: #000;
    margin: 0;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.img_and_tex_connt_abt:nth-child(1) {
    margin-right: -5%;
    margin-bottom: -5%;
}

.img_and_tex_connt_abt:nth-child(2) {
    margin-bottom: -5%;
}

.img_and_tex_connt_abt:nth-child(3) {
    margin-right: -5%;
}

.About_circle_main_div {
    margin: 150px 0;
}

.circ_abt_img_txt {
    width: 80%;
    margin: 0 auto;
    max-width: 750px;
}

.leader_team_box_con>div>div {
    border: none;
}

.leader_team_box_con .MuiButtonBase-root {
    margin-bottom: 50px;
    background-color: #1D234B;
    color: #FFF;
    box-shadow: 15px 15px 16px rgba(0, 0, 0, 0.42);

    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    max-width: 30%;
    min-width: 25%;
    border: 0px;
    border-radius: 80px 15px 15px 15px;
    width: 250px;
    height: 170px;
}

.careers_tabs_main .leader_team_box_con .MuiButtonBase-root {
    padding: 20px;
    color: white;
    max-width: 277px;
    min-width: auto;
    width: 25%;
    aspect-ratio: 1;
}

.careear_btn_div button {
    color: #FFF;
    cursor: pointer;
    margin: 50px auto 0 auto;
    background: linear-gradient(to bottom, #0091FF, #00248E);
    padding: 10px 50px;
    border: 0px;
    border-radius: 50px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.tab_class_conta::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-image: url("../images//active_arrow.png");
    background-repeat: no-repeat;
    right: -50px;
    background-size: 100%;
    transform: rotate(270deg);
    top: 50px;

}

.tab_class_conta::before {
    background-image: url("../images//active_arrow.png");
    background-size: 100%;
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    left: -50px;
    transform: rotate(90deg);
    top: 50px;
}

.leader_team_box_con .MuiTabs-indicator {
    display: none;
}

.leader_team_box_con .tab_class>div>div {
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    min-width: 850px;
    gap: 17px;
}

.leader_team_box_con .Mui-selected {
    background-color: #0069B5;
    color: white !important;
}

.tab_class .css-jpln7h-MuiTabs-scroller {
    overflow: auto !important;
}

.div_img_p_cont {
    display: flex;
}

.data_contain_tabs {
    width: 85%;
    margin: 35px auto;
    background-color: #DFE0E1;
    padding: 50px;
    border: 0px;
    border-radius: 150px 30px 30px 30px;
    box-shadow: 10px 10px 10px black;
}

.data_img {
    width: 32%;
}

.data_img img {
    width: 100%;
}

.title_and_p_leader {
    width: 67%;
    text-align: left;
    padding: 30px;
    word-break: break-all;
}

.title_and_p_leader h3 {
    color: #000;
    font-family: Avenir;
    font-size: 37px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 0;
}

.p_tabs_ p,
.title_and_p_leader p {
    color: #000;
    word-break: break-all;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.p_tabs_ {
    margin: 50px 0 0 0;
}

.main_leadership_title h1 {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.main_leadership_title {
    margin: 30px 0 100px 0;
}

.mob_menu .MuiDrawer-paper {
    background-color: #1D234B;
    color: white;
    width: 100%;
}

.mob_menu .MuiAccordion-root {
    background-color: transparent;
    box-shadow: none;
}

.mob_menu .head_mob_1.active_a span {
    color: #24A0D0;
}

.mob_menu .head_mob_1,
.mob_menu .head_mob_1 span {
    color: #FFF;
    font-family: Avenir;
    font-size: 18.85px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.emmbedde_figure_cont,
.EmbeddedLandingSolutions_title {
    position: relative;
    z-index: 5;
}

.mob_menu .head_mob_2 span {
    color: #FFF;
    text-align: center;
    font-family: Avenir;
    font-size: 16.08px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 15px;
    background-color: #0069B5;
    border: 0px;
    border-radius: 5px;
    width: fit-content;
}

.mob_menu .head_mob_2,
.mob_menu .head_mob_1 {
    width: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.logo_and_cls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.logo_btn_img {
    width: 120px;
    cursor: pointer;
}

.cls_btn_img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.logo_btn_img img,
.cls_btn_img img {
    width: 100%;
}

.emm_text .p_embedded_ {
    color: #000;
    width: 90%;
    margin: 80px auto 100px auto;
    position: relative;
    z-index: 5;
    font-family: Avenir;
    font-size: 51px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
}

.Embedded_with_image img {
    width: 60%;
}

.emm_text .p_embedded_ span {
    color: #0069B5;
}

.emm_text {
    max-width: 1250px;
    text-align: center;
    margin: 0 auto;
}

.text_emm_main_ {
    color: #000;
    width: 35%;
    text-align: left;
    padding-left: 20px;
    font-family: Avenir;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.Embedded_with_image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1250px;
    margin: 0 auto;
    padding: 37px 0 0 0;
    position: relative;
    z-index: 5;
}

.EmbeddedLandingSolutions_title h2,
.title_explore_2nd>p {
    color: #000;
    padding-top: 0px;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.title_explore_2nd>p {
    margin-top: 50px;
    padding-top: 50px;
}

.emm_pargraph p {
    color: #000;
    text-align: center;
    width: 90%;
    font-family: Avenir;
    font-size: 51px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    margin: auto;
}

.emm_pargraph p span {
    color: #0069B5;
}

.EmbeddedLandingSolutions_main_cont {
    max-width: 1250px;
    margin: 0 auto;
}

.emmbedde_figure_cont {
    padding: 60px 0;
    width: 80%;
    margin: 0 auto;
    position: relative;
    max-width: 800px;
}

.emmbedde_figure_cont svg path.circle.style {
    fill: #1D234B;
}

.emmbedde_figure_cont svg path.circle {
    transition: all 0.8s ease-in-out;
}

/* .svg_div_classs::before{position: absolute;content:"Unseured Business Term Loans";font-size: 50px;color: black;width: 100px;height: 150px;bottom: 0;} */
.g_class_1 {
    position: relative;
}

.g_class_0 path {
    fill: black;
}

.svg_div_classs svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1250px;
    top: 60px;
}

.active_text path {
    fill: white;
}

.emm_pargraph {
    width: 80%;
    margin: 0 auto;
}

.ExploreApi_imagdiv {
    position: relative;
    padding-top: 60px;
    z-index: 5;
}

.ExploreApi_imagdiv img {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

/* .cicle_add{position: absolute;} */
.circle_classes1,
.circle_classes2,
.circle_classes3,
.circle_classes4,
.circle_classes5,
.circle_classes6 {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 1.5s ease-in-out
}

.circle_classes1 {
    background-color: #F2F2F2;
    max-width: 19%;
    /* height: 190px; */
    aspect-ratio: 1;
    border: 0px;
    border-radius: 50%;
    bottom: 5%;
    left: 18%;
}

.circle_classes2 {
    background-color: #C2DAF3;
    width: 22%;
    /* height:220px; */
    aspect-ratio: 1;
    border: 0px;
    border-radius: 50%;
    bottom: 20%;
    left: 5%;
}

.circle_classes3 {
    background-color: #F2F2F2;
    width: 24%;
    /* height: 245px; */
    aspect-ratio: 1;
    border: 0px;
    border-radius: 50%;
    bottom: 41%;
    left: 0%;
}

.circle_classes4 {
    background-color: #C2DAF3;
    width: 28%;
    /* height: 280px; */
    aspect-ratio: 1;
    border: 0px;
    border-radius: 50%;
    top: 14%;
    left: 11%;
}

.circle_classes5 {
    background-color: #F2F2F2;
    width: 26%;
    /* height: 260px; */
    aspect-ratio: 1;
    border: 0px;
    border-radius: 50%;
    right: 32%;
    top: 5%;
}

.circle_classes6 {
    background-color: #C2DAF3;
    width: 28%;
    /* height: 280px; */
    aspect-ratio: 1;
    border: 0px;
    border-radius: 50%;
    top: 25%;
    right: 5%;
}

.circle_div p {
    color: #1D234B;
    margin: 0;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    pointer-events: none;
    position: relative;
    line-height: normal;
    max-width: 80%;
}

.circle_div.style {
    background-color: #1D234B;
}

.circle_div.style p {
    color: white;
}

.circle_div:hover {
    background-color: #1D234B;

}

.circle_div:hover p {
    color: white;
}

.exp_img {
    position: relative;
}

.circle_div.style p::after {
    content: "";
    position: absolute;
    bottom: -30px;
    background-image: url("../images/active_arrow.png");
    width: 40px;
    height: 22px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}

.circle_div p::after {
    content: "";
    position: absolute;
    bottom: -30px;
    background-image: url("../images/active_arrow.png");
    width: 40px;
    height: 22px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.explore_tit img {
    object-fit: contain;
    width: 15px;
}

.explore_tit {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #FFF;
    width: fit-content;
    margin: auto;
    border: 0px;
    border-radius: 50px;
    margin: 30px auto;
    left: 0;
    right: 0;
    bottom: -30px;
    background: linear-gradient(to bottom, #0091FF, #00248E);
    padding: 10px 80px;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.Explore_cont_div {
    position: relative;
}

.title_explore_2nd {
    max-width: 1250px;
    margin: 0 auto;
}

.explore_div_consume h2 {
    color: #000;
    padding: 0 30px;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.explore_div_consume p {
    color: #000;
    padding: 0px 30px;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.four_exlpore_divs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
}

.explore_div_for_data {
    width: 485px;
    position: relative;
    margin: 100px 0;
    z-index: 5;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
}

.div_1_ep .explore_div_for_data:nth-child(1) {
    background-image: url("../images//vector_img.png");
}

.div_1_ep .explore_div_for_data:nth-child(2) {
    background-image: url("../images/light_bl_2_vec.png");
}

.div_2_ep .explore_div_for_data:nth-child(1) {
    background-image: url("../images/dark_blu_3_vec.png");
}

.div_2_ep .explore_div_for_data:nth-child(2) {
    background-image: url("../images/light_blu_4_vec.png");
    background-position: top;
}

.explore_div_consume {
    width: 420px;
    height: 350px;
    position: relative;
}

.explore_div_consume::after {
    position: absolute;
    width: 100px;
    height: 100px;
    margin: auto;
    background-color: white;
    color: #000;
    font-family: Avenir;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    left: 0;
    right: 0;
}

.explore_div_for_data>img {
    position: absolute;
    bottom: -10px;
    left: 0;
    z-index: 2;
    display: none;
}

.div_1_ep .explore_div_for_data:nth-child(1) .explore_div_consume::after {
    border: 12px solid #0069B5;
    content: "1";
    top: -50px;
}

.div_1_ep .explore_div_for_data:nth-child(2) .explore_div_consume::after {
    border: 12px solid #6D92F0;
    content: "2";
    bottom: -50px;
}

.div_2_ep .explore_div_for_data:nth-child(1) .explore_div_consume::after {
    border: 12px solid #6D92F0;
    content: "3";
    top: -50px;
}

.div_2_ep .explore_div_for_data:nth-child(2) .explore_div_consume::after {
    border: 12px solid #0069B5;
    content: "4";
    bottom: -50px;
}

.div_expo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    margin: auto;
}

.explore_div_for_data:nth-child(1) .explore_div_consume>div {
    padding-top: 70px;
}

.explore_div_for_data:nth-child(2) .explore_div_consume>div {
    padding-top: 30px;
}

.explore_div_for_data:nth-child(3) .explore_div_consume>div {
    padding-top: 70px;
}

.explore_div_for_data:nth-child(4) .explore_div_consume>div {
    padding-top: 30px;
}

.PartnersLogo_main_container {
    max-width: 1250px;
    margin: 0 auto;
}

.tit_partner_say p {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.image_of_part_and_txt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1250px;
    margin: 50px auto;
    padding: 20px 0 100px 0;
}

.imag_vec_partner_say img {
    width: 100%;
}

.imag_vec_partner_say {
    width: 60%;
}

.text_of_part_say {
    width: 40%;
    z-index: 7;
}

.text_of_part_say ul {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.text_of_part_say li {
    list-style-type: none;
    text-align: left;
    position: relative;
    cursor: pointer;
    width: fit-content;
}

.ul_class_li,
.ul_class_li ul {
    width: fit-content;
}

.ul_class_li ul {
    margin: auto;
    text-align: center;
}

.ul_class_li ul li {
    padding: 5px 0;
}

.image_people img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1;
    object-position: top;
}

.text_of_part_say li::before {
    position: absolute;
    content: "";
    background-size: 100%;
    left: -30px;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    transform: rotate(270deg);
    background-repeat: no-repeat;
    background-image: url("../images/active_arrow.png");

}

.text_of_part_say li>p {
    color: #000;
    width: fit-content;
    pointer-events: none;
    font-family: Avenir;
    font-size: 33px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.Collabrate_title {
    margin: 70px auto;
}

.Collabrate_title p {
    color: #000;
    margin: 0;
    font-family: Avenir;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.PartnerWithUs_bli_title p {
    color: #FFF;
    margin: 0 auto;
    background: linear-gradient(to bottom, #0091FF, #00248E);
    padding: 10px 100px;
    border: 0px;
    border-radius: 50px;
    width: fit-content;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.div_on_hover {
    position: absolute;
    top: 70%;
    /* background-image: url("../images//what_say_hoverVector.png"); */
    background-color: #0069B5;
    left: -15%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 5;
    color: #FFF;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0px;
    width: 30vw;
    padding: 20px;
    border-radius: 20px;
    /* aspect-ratio:; */
    /* height: 310px; */
    display: none;
}

.box_otp_form input {
    text-align: center;
    text-transform: uppercase;
}

.active .div_on_hover {
    display: block;
}

.text_of_part_say .active>p {
    color: #0069B5;
}

.div_on_hover>div {
    flex-direction: column;
    /* padding-top: 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin: 0 auto;
    width: 90%;
}

.div_on_hover img {
    width: 40%;
    background-color: white;
    padding: 10px;
}

.div_on_hover p {
    margin: 0;
    width: 100%;
}

.data_of_circl p {
    color: #FFF;
    text-align: left;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.data_of_circl {
    position: absolute;
    background: linear-gradient(33deg, #00248E 17.43%, #0091FF 66.2%);
    padding: 0px 30px 0 50px;
    max-width: 720px;
    width: 50%;
    border: 0px;
    border-radius: 130px 50px 30px 30px;
    display: none;
    opacity: 0;
    transition: all 2s ease-in-out;
}

.data_of_1 {
    top: 25%;
    left: 20%;
}

.data_of_2 {
    bottom: 15%;
    left: 30%;
}

.data_of_3 {
    bottom: 20%;
    left: 20%;
}

.data_of_4 {
    top: 35%;
    left: 20%;
}

.data_of_5 {
    top: 0%;
    right: -30%;
}

.data_of_6 {
    top: 50%;
    right: 0%;
}

.show_this {
    display: block;
    opacity: 1;
}

.form_container_div .form_first {
    background-color: #1D234B;
    width: 100%;
    display: block;
    border: 0px;
    max-width: 750px !important;
    margin: 0 auto;
    padding: 0px;
    border-radius: 50px;
}

.form_container_div {
    max-width: 1250px !important;
    margin: 0 auto 50px auto;
    z-index: 5;
    position: relative;
}

.form_container_div .verify_btn {
    background: linear-gradient(to bottom, #0091FF, #00248E);
    padding: 15px 50px;
    border: 0px;
    width: 45%;
    margin: 30px 0 0 0;
    border-radius: 50px;
    color: #FFF;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.form_container_div .input_style_cl>div>div {
    margin: 11px 0;
    background-color: #FFF;
    border: 0px;
    border-radius: 50px;
    min-height: 45px;
}

.form_container_div .input_style_cl input {
    height: 35px;
    font-family: Avenir;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.form_container_div .input_style_cl label {
    color: #696A6D;
    top: calc(50% - 25px);
    left: 10px;
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.form_container_div form {
    margin: 0 auto;
    padding: 20px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.form_container_div .phone_div,
.form_container_div .email_div {
    width: 49%;
}

.btn_container {
    width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.postion_svg {
    bottom: -70%;
}

.postion_svg2 {
    bottom: 30%;
}

.postion_svg3 {
    bottom: 0;
}

.postion_svg_3 {
    bottom: 90%;
}

.PartnerWithUs_main_cont {
    position: relative;
}

.CareerBanner_image img {
    width: 100%;
}

.CareerBanner_image {
    margin: 50px 0;
}

.CareerBanner_main_container {
    max-width: 1030px;
    width: 90%;
    margin: 0 auto;
}

.CareerBanner_text p {
    color: black;
    text-align: center;
    font-family: Avenir;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.CareerBanner_text p span {
    color: #0069B5;
}

.career_vac_tit h2 {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.titel_table {
    display: flex;
    background-color: #C2DAF3;
}

.titel_table.rows_tb p {
    font-size: 20px;
}

.rows_tb div {
    justify-content: flex-start;
}

.titel_table p {
    text-align: left;
    color: #000;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.titel_table>div {
    width: 50%;
}

.career_table_div {
    max-width: 1200px;
    width: 75%;
    margin: 100px auto 50px auto;
}

.rows_tb {
    background-color: white;
    border-bottom: 1px solid black;
}

.titel_table div:nth-child(1) p {
    max-width: 90%;
    padding-left: 20px;
}

.titel_table div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
}

.careers_tabs_main .leader_team_box_con .tab_class>div>div {
    justify-content: center;
    align-items: center;
    gap: 50px;
    min-width: 650px;
}

.careers_tabs_main .data_contain_tabs {
    width: 70%;
    aspect-ratio: 2/1;
}

.distribution_img img {
    width: 100%;
}

.distribution_img {
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    max-width: 1440px;
}

.image_distribution {
    width: 60%;
}

.Dis_main_title {
    width: 40%;
}

.Dis_main_title p {
    color: #000;
    text-align: left;
    padding-left: 20px;
    font-family: Avenir;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.dat_tabs,
.tab_class {
    z-index: 5;
    position: relative;
}

.careers_tabs_main .p_tabs_ {
    overflow: auto;
    aspect-ratio: 2/1;
}

.distribution_ban_text h2 {
    max-width: 1050px;
    color: #000;
    font-family: Avenir;
    font-size: 51px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: normal;
}

.distribution_ban_text span {
    color: #0069B5;
}

.distribution_ban_text p {
    color: #000;
    text-align: center;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.distribution_ban_text {
    max-width: 1050px;
    margin: 0 auto;
    width: 90%;
}

.titl_section_ouroff p {
    color: #000;
    max-width: 970px;
    margin: 150px auto 50px auto;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.para_section_ouroffer p {
    color: #000;
    max-width: 970px;
    margin: 50px auto;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.after_ouroffer_p {
    max-width: 1025px;
    margin: 0 auto;
}

.after_ouroffer_p p {
    color: #000;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.OurDistributionPlatform_title h2 {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0 0 45px 0;
}

.OurDistributionPlatform_p p {
    color: #000;
    margin: 60px auto;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.OurDistributionPlatform {
    max-width: 1050px;
    margin: 0 auto;
    z-index: 5;
    position: relative;
}

.BenefitsOfBecoming_title p {
    color: #000;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.BenefitsOfBecoming_image_cont {
    display: flex;
    position: relative;
}

.BenefitsOfBecoming_image_cont img {
    width: 80%;
}

.benifits_points_div {
    position: absolute;
    right: 100px;
    max-width: 550px;
}

.benifits_points_div ul {
    padding: 0;
    margin: 0;
}

.benifits_points_div ul li {
    margin: 20px 0;
    text-align: left;
    list-style: none;
    color: #0069B5;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.collabra_opp_div {
    max-width: 80%;
    margin: 50px auto;
}

.collabra_opp_div p {
    color: #000;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.collabra_opp_div h2 {
    color: #000;
    margin: 95px 0 0 0;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.our_offer_divs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    z-index: 9;
    position: relative;
    margin: 20px auto 150px auto;
    max-width: 1050px;
}

.Select_data_form {
    width: 100%;
    margin-top: 10px !important;
}

/* .yt_icon,
.insta_icon {
    border: 0.1px solid white;
    border-radius: 50%;
    width: 30px;
    overflow: hidden;
    padding:2px;
}

.yt_icon img,
.insta_icon img {
    width: 100%;
} */

.select_data,
.Select_data_form ul li,
.Select_data_form>div,
.Select_data_form label {
    color: black;
    font-family: Avenir !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal !important;
    text-align: left;
}

.Select_data_form label {
    display: none;
}

.Tab_accord_form_main .Select_data_form>div {
    background-color: white !important;
    border-radius: 0px;
    margin-bottom: 10px;
    box-shadow: 6px 6px 12px rgba(21, 75, 124, 0.60);
}

.shape_vector_divs img {
    width: 100%;
}

.Tab_accord_form_main .verify_otp {
    /* color: black; */

    width: max-content;
    border: 1px solid white;
    border-radius: 50px;
    font-family: Avenir;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.shape_vector_divs {
    width: 33%;
    padding: 0px;
    z-index: -1;
    aspect-ratio: 1;
}

.OurOffer_pagraph {
    cursor: pointer;
    width: 90%;
    margin: auto;
    z-index: 8;
    position: relative;
    aspect-ratio: 1;
    transition: all 1s ease-in-out;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    /* background-image: url("../images/vector_normal.png"); */
}

.Select_data_form fieldset span {
    color: #0069B5;
    /* opacity: 1; */
}

.shape_vector_divs .image_vactor {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;
    width: 90%;
    aspect-ratio: 1;
}

.our_offer_through_container {
    position: relative;
}

.shape_vector_divs:nth-child(2) .OurOffer_pagraph {
    /* background-image: url("../images/distribu_2nd.png"); */
}

.shape_vector_divs .style_add {
    /* background-image: url("../images/vector_active.png") !important; */
}

.shape_vector_divs .OurOffer_pagraph::before {
    content: "";
    width: 48px;
    height: 28px;
    background-image: url("../images/Distribute_Vector.png");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -30px;
    transition: all 1s ease-in-out;
    opacity: 0;
}

.shape_vector_divs .style_add::before {
    opacity: 1;
}

.OurOffer_pagraph p {
    color: #0069B5;
    width: 60%;
    margin: 0 auto;
    pointer-events: none;
    transition: all 1s ease-in-out;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.style_add.OurOffer_pagraph p {
    color: white;
}

.header_main_cont {
    z-index: 10;
}

.OurOfferSection_main_cont {
    max-width: 1450px;
    margin: 0px auto 100px auto;
    z-index: 6;
    position: relative;
}

.BenefitsOfBecoming_title {
    margin: 100px auto 85px auto;
}

.ourDistribu_video {
    max-width: 735px;
    margin: 0 auto;
    aspect-ratio: 2/1;
    width: 80%;
}

.ourDistribu_video iframe {
    width: 100%;
    height: calc(100% + 15%);
    border-radius: 50px;
    border: 0px;
}

.distrib_class .silderr_partner_logo {
    margin: 60px auto 20px auto;
}

.distribution_div_menu_list {
    position: absolute;
    background: linear-gradient(11deg, #00248E 16.81%, #0091FF 66.5%);
    color: white;
    color: #FFF;
    display: none;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    z-index: 9;
    line-height: normal;
    width: 80%;
    top: 350px;
    border: 0px;
    border-radius: 60px;
    left: 0;
    right: 0;
    margin: auto;
}

.shape_vector_divs:nth-child(3) .distribution_div_menu_list {
    right: 0px;
    left: unset;
}


.tab_class_conta>div::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}

.tab_class_conta>div::-webkit-scrollbar-track {
    background: transparent;
}

.tab_class_conta>div::-webkit-scrollbar-thumb {
    background-color: #1D234B;
    border-radius: 10px;
}

.tab_class_conta>div::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


.distribution_div_menu_list ul {
    padding: 10px;
    /* gap: 15px; */
    /* justify-content: space-between; */
    /* overflow-y: auto; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.distribution_div_menu_list ul::-webkit-scrollbar {
    width: 10px;
}

.distribution_div_menu_list ul::-webkit-scrollbar-track {
    background: transparent;
}

.distribution_div_menu_list ul::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
}

.distribution_div_menu_list ul::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.distribution_div_menu_list ul li {
    list-style: none;
    width: calc(25% - 25px);
    border-left: 1px solid white;
    min-height: 55px;
    border-right: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    word-break: break-word;
    margin: 0px 0;
}

.distribution_div_menu_list ul li:nth-child(odd) {
    border-left: 0;
}

.distribution_div_menu_list ul li:nth-child(even) {
    border-left: 0;
}

.distribution_div_menu_list ul li:nth-child(4) {
    border: 0;
}

.distribution_div_menu_list ul li:nth-child(8) {
    border: 0;
}

.distribution_div_menu_list ul li:nth-child(12) {
    border: 0;
}

.distribution_div_menu_list ul li:nth-child(16) {
    border: 0;
}

.style_add .distribution_div_menu_list {
    display: block;
}

.Accordion_tabs_class {
    color: #000;
    margin: 0;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.accordin_click_class p {
    color: #FFF;
    z-index: 3;
    width: 50%;
    border: 0px;
    border-radius: 50px;
    padding: 20px 50px;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background: linear-gradient(0deg, #00248E 3.13%, #0091FF 78.38%);
}

.accordin_click_class>div {
    justify-content: center;
    width: 100%;
    position: relative;
}

.accordin_click_class {
    flex-direction: column;
    position: relative;
    width: 50%;
}

.Tab_accord_form_main {
    width: 65%;
    margin: 0 auto;
}

.Tab_accord_form_main .form_first {
    width: 100%;
    background-color: #dcdde4;
    border: 0px;
    border-radius: 25px;
    padding: 0;
}

.Tab_accord_form_main .form_first p b {
    font-size: 20px;
}

.Tab_accord_form_main .form_first p {
    color: #FFF;
    margin: 10px 0;
    width: 100%;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AccordianOurOffer_main_cont .main_accord {
    box-shadow: none;
    background-color: transparent;
}

.AccordianOurOffer_main_cont .main_accord::before {
    display: none;
}

.Tab_accord_form_main .form_first form input {
    color: black;
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Tab_accord_form_main .input_style_cl label {
    transform: none;
    color: #696A6D;
    top: calc(40% - 18px);
    left: 10px;
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Tab_accord_form_main .btn_container button {
    width: fit-content;
    background: linear-gradient(to bottom, #0091FF, #00248E);
    padding: 10px 80px;
    border: 0px;
    width: 50%;
    margin: 20px 0 0 0;
    border-radius: 50px;
    color: #FFF;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

 .get_Started {
    padding: 10px 0px !important;
    
}
.Tab_accord_form_main .input_style_cl {}

.back_tab2,
.back_tab1 {
  position: absolute; /* Use absolute positioning instead of fixed */
  left: 10%;
  width: 80%;
  height: 40%;
  z-index: 2;
  bottom: 10%;
  transition: transform 0.5s ease-in-out, bottom 0.5s ease-in-out; /* Transition for smooth motion */
  border: 0px;
  border-radius: 50px;
}

.back_tab2 {
    background-color: #DFE0E1;
}

.back_tab1 {
    background-color: #1D234B;
}

.Tab_accord_form_main form {
    padding: 50px;
}

.Tab_accord_form_main .input_style_cl>div>div {
    margin: 10px 0;
    border: 0px;
    border-radius: 0px;
    overflow: hidden;
    height: 45px;
    background-color: #FFF;
}

.Tab_accord_form_main .phone_div,
.Tab_accord_form_main .email_div {
    width: 48%;
}

.Tab_accord_form_main form {
    margin: 0 auto;
    padding: 20px;
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.Mui-expanded .back_tab2,
.Mui-expanded .back_tab1 {
    opacity: 0;
}

#panel1-content {
    padding-bottom: 90px;
}

.Tab_accord_form_main.Tab_2_acc .form_first {
    /* background-color: #DFE0E1; */
}

.Tab_accord_form_main.Tab_2_acc .input_style_cl>div>div {
    box-shadow: 6px 6px 12px rgba(21, 75, 124, 0.60);
}

.Tab_accord_form_main.Tab_2_acc .form_first p {
    color: #000;
}

.AccordianOurOffer_main_cont_div {
    position: relative;
}

.Tab_2_acc .text_form_tit {
    color: black;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Tab_accord_form_main.Tab_2_acc .input_style_cl p,
.error_msg {
    text-align: center;
}

.Tab_2_acc .form_first form input {
    text-align: center;
}

.Tab_accord_form_main .input_style_cl label {
    display: none;
}

.Tab_accord_form_main.Tab_2_acc fieldset legend {
    width: 0%;
}

.Tab_2_acc .box_otp_form {
    box-shadow: 6px 6px 12px rgba(21, 75, 124, 0.60);
}

.AccordianOurOffer_main_cont,
.distribution_position_div,
.our_offer_sec_position {
    position: relative;
}

.distri_svg1 {
    bottom: 20%;
}

.distri_svg2 {
    bottom: -20%;
}

.distri_svg3 {
    bottom: unset;
    top: 0%;
}


.AccordianOurOffer_scetion {
    z-index: 5;
    position: relative;
}

.AccordianOurOffer_main_cont {
    margin: 0px auto 150px auto;
    max-width: 750px;
    z-index: 6;
}

.Investor_rel_banner_div img {
    width: 100%;
}

.Investor_rel_banner_div {
    width: 60%;
}

.Investor_ban_title {
    width: 40%;
}

.Investor_ban_title h2 {
    color: #000;
    font-family: Avenir;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.Tab_accord_form_main.Tab_2_acc .text_form_tit_label {
    margin-bottom: 0;
}

.InvestorBanner_main_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
}

.Investor_titleClass h2 {
    color: #000;
    margin-top: 0;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.shape_investorBusiness_div img {
    width: 100%;
}

.shape_investorBusiness_div {
    position: relative;
}

.investor_fourh_shapes_div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 80%;
    max-width: 1100px;
    margin: 90px auto;
}

.two_investor_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.investorValue_paragaph {
    width: 1050px;
    margin: 0 auto;
}

.investorValue_paragaph p {
    color: #000;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.InvestorDarkBlue_main_p_sec {
    background-color: #1D234B;
    padding: 85px;
}

.InvestorLightBlue_main_section {
    background-color: #0069B5;
    padding: 85px;
}

.InvestorDarkBlue_main_p_sec p {
    color: #FFF;
    padding: 55px 0;
    position: relative;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.InvestorDarkBlue_main_p_sec p::after {
    position: absolute;
    height: 2px;
    width: 200px;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: white;
    bottom: 0;
}

.InvestorDarkBlue_main_p_sec p:nth-child(2):after {
    opacity: 0;
}

.InvestorDarkBlue_main_p_sec p:nth-child(2) {
    padding-bottom: 0;
    margin-bottom: 0;
}

.InvestorLightBlue_main_section p {
    color: #FFF;
    position: relative;
    font-family: Avenir;
    padding: 50px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.InvestorLightBlue_main_section p::after {
    position: absolute;
    height: 2px;
    width: 200px;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: white;
    bottom: 0;
}

.InvestorLightBlue_main_section p:nth-child(2):after {
    opacity: 0;
}

.InvestorLightBlue_main_section p:nth-child(2) {
    padding-bottom: 0;
    margin-bottom: 0;
}

.main_shape_titleInvestor {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 60%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0069B5;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.number_div {
    color: #FFF;
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    margin: auto;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.cicle_icons {
    width: 55px;
    border: 8px solid #0069B5;
    overflow: hidden;
    aspect-ratio: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -50px;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cicle_icons img {
    width: 45px;
}

.two_investor_cont .shape_investorBusiness_div:nth-child(2) .cicle_icons {
    border-color: #6D92F0;
}

.InvestorRetailsTabs_main_container {
    width: 90%;
    max-width: 1350px;
    margin: 100px auto;
    z-index: 5;
    position: relative;
}

.tab_class_conta button {
    width: 14%;
    aspect-ratio: 1;
    color: black;
    word-break: break-word;
    text-transform: capitalize;
    height: 150px;
    text-align: center;
    font-family: Avenir;
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    /* 114.286% */
}

.tab_class_conta button p {
    padding: 0 5px;
}

.tab_class_conta button.Mui-selected .tab_container::after {
    display: none;
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    bottom: -50px;
    background-size: 100%;
    background-image: url("../images//active_arrow.png");
}

.tab_class_conta button.Mui-selected {
    color: white;
    /* background-color:#1D234B; */
    position: relative;
}



.tab_class_conta>div>div {
    justify-content: space-between;
    align-items: center;
    width: 1300px;
}

.tab_class_conta>div {
    overflow-x: auto !important;
    padding-bottom: 20px;
}

.tab_class_conta>div>span {
    opacity: 0;
}

.brand-button a {
    text-decoration: none;
    color: inherit;
}

.tab_container {
    position: relative;
}

.feed_btn a {
    padding: 8px 10px;
    text-decoration: none;
    color: inherit;
    transition: 0.3s;
}

@media (max-width: 750px) {
    .feed_btn a {
        padding: 4px 8px;
        font-size: 14px;
    }
}

.tab_class_conta button {
    border-radius: 20px;
    background-color: transparent;
}

.tab_class_conta button:nth-child(even) {
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../images/light_blue_arr.png");
}

.tab_class_conta button:nth-child(odd) {
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../images/dark_blue_arr.png");
}

.tab_class_conta button.Mui-selected:nth-child(even) {
    background-image: url("../images/active_light_img.png");
}

.tab_class_conta button.Mui-selected:nth-child(odd) {
    background-image: url("../images/active_dark_img.png");

}

.InvestorRetailsTabs_main_container .tab_class_conta {
    overflow: auto;
}

.InvestorNewTabs_cont {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.InvestorNewTabs_cont .p_tabs_ {
    background: linear-gradient(to right, #00248E, #0091FF);
    margin: 0 !important;
    padding: 25px 50px;
    width: 25%;
}

.InvestorNewTabs_cont .p_tabs_ h2 {
    color: #FFF;
    word-break: break-word;
    font-family: Avenir;
    font-size: 33px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.InvestorNewTabs_cont .p_tabs_ p {
    color: #FFF;
    /* padding: 0 50px; */
    word-break: break-word;
    text-align: center;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 120% */
}

.InvestorNewTabs_cont .acord_cont {
    width: 65%;
    border: 1px solid lightgray;
}

.InvestorNewTabs_cont .acordian_ivest {
    box-shadow: none;
    border-radius: 0 !important;
}

.InvestorNewTabs_cont .acordian_main_invest {
    border-bottom: 2px solid #6D92F0;
}

.InvestorNewTabs_cont .acordian_ivest p {
    margin: 0;
    color: #000;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.InvestorNewTabs_cont .arr_down_tab {
    width: 70%;
}

.MiniMoreTaB::before,
.MiniMoreTaB::after {
    display: none;
}

.MiniTabContent>div {
    padding: 15px;
}

.MiniMoreTaB {
    padding: 0 !important;
}

.acord_cont .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(270deg);
    align-items: center;
    justify-content: center;
}

.mini_tabs button:hover,
.InvestorNewTabs_cont .acordian_ivest p:hover {
    color: #0161CD;
}

.mini_tabs button {
    color: #000;
    text-transform: capitalize;
    padding: 0;
    margin: 0 20px;
    font-family: Avenir;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Leadership_team_main_cont .tabs_data_leader>div {
    padding: 0;
}

.mini_tabs button:nth-child(1) {
    margin: 0;
}

.tab_mini_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
}

.tab_mini_content .dowlod_block {
    display: flex;
    cursor: pointer;
    width: 21%;
    background-color: #EAEEFA;
    padding: 5px;
    align-items: flex-start;
    aspect-ratio: 2/1;
}

.tab_mini_content .dowlod_block p {
    color: #000;
    text-align: left;
    width: 90%;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.imgdownload img {
    width: 100%;
}

.mini_tabs button.Mui-selected {
    color: #0161CD
}

.mini_tabs .MuiTabs-indicator {
    display: none;
}

.InvestorRetailsTabs_main_container .dat_tabs>div,
.MiniMoreTaB .dat_tabs>div {
    padding: 20px 0;
}

.Leadership_team_main_cont {
    max-width: 1150px;
    margin: 0 auto;
}

.mini_tabs>div {
    padding: 0 20px !important;
}

.mini_tabs>div>div {
    width: 600px;
}

.mini_tabs {
    position: relative;
}

.MiniMoreTaB::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 30px;
    background-image: url("../images/active_arrow.png");
    background-repeat: no-repeat;
    right: -20px;
    background-size: 100%;
    transform: rotate(270deg);
    z-index: 8;
    top: 100px;
}

.MiniMoreTaB::before {
    position: absolute;
    z-index: 8;
    content: "";
    width: 20px;
    height: 30px;
    background-image: url("../images/active_arrow.png");
    background-repeat: no-repeat;
    left: -20px;
    background-size: 100%;
    transform: rotate(90deg);
    top: 100px;
}

.peopele_data_with_img:hover .name_title_of_people h2 {
    color: #0069B5;
}

.container_of_peop_data {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.NIYOGINfeed_main_cont {
    z-index: 99;
    position: relative;
}

.peopele_data_with_img {
    width: 22%;
    cursor: pointer;
}

.image_people img {
    width: 100%;
}

.name_title_of_people h2 {
    color: black;
    transition: 0.5s all ease-in-out;
    text-align: center;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.name_title_of_people p {
    color: #000;
    text-align: center;
    font-family: Avenir;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.model_of_people_data h1 {
    color: #FFF;
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 0;
}

.model_of_people_data h5 {
    color: #FFF;
    margin: 21px 0;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.model_of_people_data p {
    color: #FFF;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 160% */
}

.level_two_menu {
    position: absolute;
    padding: 20px 0 0 0;
    top: 30px;
    width: 150px;
    display: none;
}

.level_two_menu>li {
    background-color: #0069B5;
    margin: 10px 0;
    padding: 10px;
    display: flex;
}

.active_this>a {
    color: rgb(36, 160, 208) !important;
}

.active_this .level_two_menu {
    display: block;
}

.level_two_menu>li.level_three_menu {
    background-color: #009DFF;
    position: relative;
}

.level_two_menu>li.level_three_menu::after {
    position: absolute;
    content: "";
    background-image: url("../images/white_arr_menu.png");
    background-repeat: no-repeat;
    background-size: 100%;
    right: 20px;
    width: 10px;
    height: 20px;
    margin: auto;
    top: 0;
    bottom: 0;
}

.level_4_menu {
    position: absolute;
    padding: 0;
    right: -130px;
    top: -10px;
}

.level_4_menu>li {
    background-color: #0069B5;
    margin: 10px 0;
    padding: 8px 0px 8px 8px;
    display: flex;
    width: 100px;
    text-align: left;
    display: none;
}

.active_this_li .level_4_menu>li {
    display: block;
}

.tab_of_mobile_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.model_of_career .title_model_carrer {
    color: #FFF;
    margin-bottom: 0;
    text-align: center;
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.model_of_career .btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.model_of_career .call_back_btn {
    color: #FFF;
    padding: 10px 25px;
    text-align: center;
    width: fit-content;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background: linear-gradient(0deg, #00248E 3.12%, #0091FF 78.38%);
    border-radius: 50px;
}

.model_of_career form {
    width: 100%;
}

/* .model_of_career .input_style_cl{padding: 10px 0;} */
.model_of_career .input_style_cl>div>div {
    margin: 12px 0;
    background-color: #FFF;
    border: 0px;
    border-radius: 50px;
    min-height: 25px;
}

.model_of_career .input_style_cl label {
    color: #696A6D;
    top: calc(50% - 25px);
    left: 10px;
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.model_of_career .input_style_cl input {
    height: 25px;
    font-family: Avenir;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.model_cont_vcar>div {
    width: 600px;
    border-radius: 50px;
}

.form_Career_cont {
    padding: 30px !important;
}

.file_upload_div {
    background-color: white;
    padding: 0px;
    border-radius: 20px;
    margin: 12px 0;
}

.file_upload_div .input_style_cl {
    padding: 10px 25px;
    justify-content: space-between;
}

.file_upload_div .input_style_cl .btn_file_upload {
    color: white;
    background: linear-gradient(0deg, #00248E 3.12%, #0091FF 78.38%);
    font-family: Avenir;
    border: 0px;
    border-radius: 50px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.file_upload_div .input_style_cl h1 {
    color: #696A6D;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.imgdownload {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: fit-content;
}



/* media querys css */
@media screen and (max-width: 1450px) {
    .title_and_p_leader h3 {
        font-size: 37px;
    }

}

@media screen and (max-width: 1350px) {

    /* .Investor_titleClass h2{font-size: 2px;} */
    .number_div {
        font-size: 18px;
        bottom: 10px;
    }

    .main_shape_titleInvestor {
        font-size: 19px;
    }

    .Investor_ban_title h2 {
        font-size: 58px;
    }

    .OurDistributionPlatform_title h2,
    .BenefitsOfBecoming_title p,
    .distrib_class .part_title {
        font-size: 28px;
    }

    .postion_svg {
        bottom: -30%;
    }

    .form_container_div .form_first {
        max-width: 80% !important;
    }

    .Collabrate_title p {
        font-size: 40px;
    }

    .text_of_part_say li>p {
        font-size: 30px;
    }

    .explore_div_for_data {
        width: 420px;
    }

    .explore_div_consume {
        width: 400px;
        height: 320px;
    }

    .explore_div_consume::after {
        width: 80px;
        height: 80px;
    }

    .explore_div_consume::after {
        font-size: 48px;
    }

    .explore_div_consume p {
        font-size: 14px;
    }

    .title_explore_2nd>p {
        font-size: 38px;
    }

    .PartnerWithUs_bli_title p,
    .explore_tit {
        font-size: 20px;
    }

    .emm_pargraph p {
        font-size: 45px;
    }

    .emm_text .p_embedded_ {
        font-size: 45px;
    }

    .emm_text,
    .Embedded_with_image {
        max-width: 80%;
    }

    .text_emm_main_ {
        font-size: 52px;
    }

    .title_and_p_leader h3 {
        font-size: 32px;
    }

    .Dis_main_title p {
        font-size: 57px;
    }

    /* .p_tabs_ p, .title_and_p_leader p{font-size: 21px;} */
    .leader_team_box_con .MuiButtonBase-root {
        /* font-size: 30px; */
        border-radius: 100px 20px 20px 20px;
        width: 350px;
        height: 250px;
    }

    .menu_cont>ul>li::before {
        bottom: -18px;
    }

    .menu_cont>ul>li::after {
        bottom: -5px;
    }

    .text_tit_p_abt {
        top: 20%;
    }

    /* .text_tit_p_abt h2{font-size: 45px;} */
    /* .text_tit_p_abt p{font-size: 22px;} */
    /* .abt_text_tit_p h2{font-size: 80px;} */
    /* .abt_text_tit_p p{font-size: 20px;} */
    .feed_tit {
        font-size: 18px;
    }

    .feed_paragaph {
        font-size: 14px;
    }

    .feed_btn {
        font-size: 19px;
    }

    .circle_numbers {
        font-size: 49px;
    }

    .circle_title {
        font-size: 20px;
    }

    .menu_cont>ul>li a {
        font-size: 19px;
    }

    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 28px;
    }

    .div_on_hover {
        font-size: 18px;
    }

    /* .benifits_points_div ul li{font-size: 38px;} */
    .benifits_points_div {
        width: 40vw;
        right: 5vw;
    }

    .investorValue_paragaph p {
        font-size: 25px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 22px;
    }


    .data_of_4 {
        left: 27%;
        top: 32%;
    }

    .data_of_5 {
        left: 40%;
        top: 30%;
    }
}

@media screen and (max-width: 1250px) {
    .investorValue_paragaph p {
        font-size: 23px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 20px;
    }

    .Investor_ban_title h2 {
        font-size: 52px;
    }

    .OurDistributionPlatform_title h2,
    .BenefitsOfBecoming_title p,
    .distrib_class .part_title {
        font-size: 25px;
    }

    .para_section_ouroffer p,
    .titl_section_ouroff p,
    .after_ouroffer_p {
        width: 80%;
    }

    .titl_section_ouroff p {
        font-size: 28px;
    }

    .OurDistributionPlatform_p p,
    .after_ouroffer_p p,
    .para_section_ouroffer p {
        font-size: 20px;
    }

    .postion_svg {
        bottom: -25%;
    }

    .Collabrate_title p {
        font-size: 41px;
    }

    .div_on_hover {
        font-size: 15px;
    }

    .text_of_part_say li>p {
        font-size: 28px;
    }

    .explore_div_for_data {
        width: 420px;
    }

    .explore_div_consume {
        width: 380px;
        height: 320px;
    }

    .explore_div_consume::after {
        width: 80px;
        height: 80px;
    }

    .explore_div_consume::after {
        font-size: 43px;
    }

    .explore_div_consume h2 {
        font-size: 18px;
    }

    .explore_div_consume p {
        font-size: 13px;
    }

    .tit_partner_say p,
    .title_explore_2nd>p {
        font-size: 35px;
    }

    .data_of_circl p {
        font-size: 18px;
    }

    /* .data_of_circl{width: 550px;} */
    .data_of_1 {
        /* bottom: -33%; */
    }

    .data_of_2 {
        bottom: 9%;
    }

    .data_of_3 {
        /* bottom: 3%; */
    }

    .PartnerWithUs_bli_title p,
    .explore_tit {
        font-size: 18px;
    }

    .Dis_main_title p {
        font-size: 53px;
    }

    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 25px;
    }

    .emm_pargraph p {
        font-size: 40px;
    }

    .title_and_p_leader h3 {
        font-size: 28px;
    }

    /* .p_tabs_ p, .title_and_p_leader p{font-size: 21px;} */
    .leader_team_box_con .MuiButtonBase-root {
        /* font-size: 25px; */
        border-radius: 100px 20px 20px 20px;
        width: 300px;
        height: 230px;
    }

    /* .text_tit_p_abt h2{font-size: 41px;} */
    /* .text_tit_p_abt p{font-size:20px ;} */
    /* .abt_text_tit_p h2{font-size: 70px;} */
    .abt_text_tit_p p {
        font-size: 18px;
    }

    .feed_tit {
        font-size: 17px;
    }

    .feed_paragaph {
        font-size: 13px;
    }

    .feed_btn {
        font-size: 18px;
    }

    .menu_cont>ul>li {
        margin: 0 10px;
    }

    .header_main_cont_div {
        padding: 10px 30px;
    }

    .blk_img_Oursubsidiaries img {
        width: 70px;
        height: 70px;
    }

    .block_data_cont_Oursubsidiaries {
        border-radius: 70px 20px 20px 20px;
    }

    .blk_readmore_Oursubsidiaries {
        font-size: 16px;
    }

    .blk_tit_Oursubsidiaries {
        font-size: 22px;
    }

    .text_banner {
        /* top: 25%; */
        left: 5%;
    }

    .first_txt {
        font-size: 50px
    }

    .sec_txt {
        font-size: 58px;
    }

    .menu_cont>ul>li a {
        font-size: 17px;
    }

    .header_btn ul li a {
        font-size: 15px;
    }

    .logo_cont a {
        width: 150px;
    }

    .leding_and_disti_blocks {
        width: 90%;
    }

    .blk_tit {
        font-size: 20px;
    }

    .blk_readmore_ {
        font-size: 19px;
    }

    .block_data_cont {
        width: 37%;
    }

    .p_tabs_ {
        margin: 30px 0 0 0;
    }

    .circle_data_cont {
        margin: 100px auto 100px auto;
    }


}

@media screen and (max-width: 1150px) {
    .investorValue_paragaph p {
        font-size: 21px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 19px;
    }

    .investorValue_paragaph {
        width: 80%;
        margin: 0 auto;
    }

    .Investor_titleClass h2 {
        font-size: 28px;
    }

    .cicle_icons {
        width: 40px;
    }

    .cicle_icons img {
        width: calc(100% - 5px);
    }

    .number_div {
        bottom: 8px;
    }

    .Investor_ban_title h2 {
        font-size: 42px;
    }

    .shape_vector_divs .OurOffer_pagraph::before {
        bottom: -25px;
        width: 10%;
        aspect-ratio: 2/1;
    }

    .OurDistributionPlatform_title h2,
    .BenefitsOfBecoming_title p,
    .distrib_class .part_title {
        font-size: 22px;
    }

    .titl_section_ouroff p {
        font-size: 23px;
    }

    .OurDistributionPlatform_p p,
    .after_ouroffer_p p,
    .para_section_ouroffer p {
        font-size: 18px;
    }

    .postion_svg {
        bottom: -5%;
    }

    .Collabrate_title p {
        font-size: 38px;
    }

    .div_on_hover {
        font-size: 12px;
        /* width: 220px;
        height: 180px; */
    }

    .div_on_hover>div {
        /* padding-top: 60px; */
    }

    .text_of_part_say li>p {
        font-size: 24px;
    }

    .explore_div_for_data {
        width: 400px;
    }

    .explore_div_consume {
        width: 350px;
        height: 310px;
    }

    .explore_div_consume::after {
        width: 80px;
        height: 80px;
    }

    .explore_div_consume::after {
        font-size: 38px;
    }

    /* .explore_div_consume h2{font-size: 23px;} */
    .explore_div_consume p {
        font-size: 12px;
    }

    .tit_partner_say p,
    .title_explore_2nd>p {
        font-size: 32px;
    }

    /* .data_of_circl p{font-size: 25px;} */
    .data_of_1 {
        /* bottom: -27%; */
    }

    .data_of_2 {
        bottom: 2%;
    }

    .data_of_3 {
        /* bottom: 9%; */
    }

    .data_of_4 {
        top: 38%;
    }

    .data_of_5 {
        top: 25%;
    }

    .data_of_6 {
        top: 50%;
    }

    .number_div {
        bottom: 7px;
    }

    /* .PartnerWithUs_bli_title p,.explore_tit{font-size: 14px;} */
    /* .circle_div p{font-size: 13px;} */
    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 23px;
    }

    .text_emm_main_ {
        font-size: 50px;
    }

    .emm_text .p_embedded_ {
        font-size: 35px;
    }

    .main_leadership_title h1 {
        font-size: 29px;
    }

    .p_tabs_ {
        margin: 20px 0 0 0;
    }

    .title_and_p_leader h3 {
        font-size: 28px;
    }

    .p_tabs_ p,
    .title_and_p_leader p {
        font-size: 17px;
    }

    /* .text_tit_p_abt h2{font-size: 38px;} */
    /* .text_tit_p_abt p{font-size: 18px;} */
    .abt_text_tit_p {
        width: 45%;
    }

    .abt_text_tit_p h2 {
        font-size: 60px;
    }

    .abt_text_tit_p p {
        font-size: 17px;
    }

    .block_slider_feed {
        bottom: -20%;
    }

    .slider_part_class .slick-next:before {
        width: 32px;
        height: 32px;
    }

    .slider_part_class .slick-prev:before {
        width: 32px;
        height: 32px;
    }

    .circle_numbers {
        font-size: 43px;
    }

    .circle_title {
        font-size: 18px;
    }

    .blk_img_Oursubsidiaries {
        padding: 7px;
    }

    .blk_tit {
        font-size: 19px;
    }

    .text_banner {
        /* top: 25%; */
        left: 6%;
    }

    .first_txt {
        font-size: 40px
    }

    .sec_txt {
        font-size: 48px;
    }

    .menu_cont>ul>li a {
        font-size: 15px;
    }

    .header_btn ul li a {
        font-size: 13px;
    }

    .logo_cont a {
        width: 120px;
    }

    .Dis_main_title p {
        font-size: 45px;
    }

    .footer_sec_div li a b,
    .footer_sec_div b {
        font-size: 18px;
        line-height: 40px;
    }

    .footer_sec_div li a,
    .footer_sec_div {
        font-size: 14px;
        line-height: 40px;
    }

    .ls_footer_con a {
        font-size: 20px;
        text-align: left;
    }

    .social_logo_cont p {
        font-size: 17px;
    }

    .social_logo_cont a {
        margin: 0 12px;
        text-align: left;
    }

    .pragram_two_div p {
        color: #000;
        font-family: Avenir;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .leader_team_box_con .MuiButtonBase-root {
        /* font-size: 25px; */
        border-radius: 100px 20px 20px 20px;
        width: 270px;
        height: 230px;
    }

    .OurDistributionPlatform_p {
        width: 80%;
        margin: 0 auto;
    }

    .InvestorLightBlue_main_section,
    .InvestorDarkBlue_main_p_sec {
        padding: 50px 50px 80px 50px;
    }

}

@media screen and (max-width: 1000px) {
    .model_cont_vcar>div {
        width: 80%;
    }

    .name_title_of_people h2 {
        font-size: 20px;
    }

    .Dis_main_title p {
        font-size: 42px;
    }

    .tab_class_conta>div>div {
        width: 1100px;
    }

    .InvestorNewTabs_cont .p_tabs_ {
        padding: 20px;
        width: 30%;
    }

    .tab_class_conta button {
        font-size: 14px;
    }

    .tab_class_conta button {
        height: 125px;
    }

    .InvestorNewTabs_cont .acordian_ivest p {
        font-size: 20px;
    }

    .InvestorNewTabs_cont .p_tabs_ h2 {
        font-size: 28px;
        margin-top: 0;
    }

    .tab_mini_content .dowlod_block {
        width: 20%;
    }

    .mini_tabs button {
        font-size: 14px;
    }

    .tab_mini_content .dowlod_block p {
        font-size: 13px;
    }

    .InvestorNewTabs_cont .p_tabs_ p {
        font-size: 18px;
        /* padding: 0 20px; */
    }

    .InvestorNewTabs_cont .arr_down_tab {
        width: 50%;
    }

    .investorValue_paragaph p {
        font-size: 20px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 18px;
    }

    .main_shape_titleInvestor {
        font-size: 17px;
    }

    .number_div {
        font-size: 15px;
    }

    .Investor_ban_title h2 {
        font-size: 40px;
    }

    /* .Tab_accord_form_main .input_style_cl>div>div{height: 65px;} */
    .box_otp_form>div {
        height: 30px;
    }

    .benifits_points_div ul li {
        margin: 10px 0;
    }

    .benifits_points_div ul li {
        font-size: 27px;
    }

    .OurDistributionPlatform_title h2,
    .BenefitsOfBecoming_title p,
    .distrib_class .part_title {
        font-size: 20px;
    }

    .titl_section_ouroff p {
        font-size: 22px;
    }

    .OurDistributionPlatform_p p,
    .after_ouroffer_p p,
    .para_section_ouroffer p {
        font-size: 16px;
    }

    .OurOffer_pagraph p {
        font-size: 28px;
    }

    /* .distribution_ban_text h2{font-size: 50px;} */
    .distribution_ban_text p {
        font-size: 18px;
    }

    .postion_svg {
        bottom: 5%;
    }

    .career_table_div {
        margin-top: 50px;
    }

    /* .CareerBanner_text p{font-size: 56px;} */
    /* .career_vac_tit h2{font-size: 30px;} */
    .titel_table p {
        font-size: 21px;
    }

    /* .form_container_div .verify_btn{font-size: 18px;width: fit-content;} */
    .Collabrate_title p {
        font-size: 33px;
    }

    /* .div_on_hover{width: 320px;height: 320px;} */
    /* .div_on_hover>div{padding-top: 90px;} */
    .text_of_part_say li>p {
        font-size: 20px;
    }

    .explore_div_for_data {
        width: 370px;
    }

    .explore_div_consume {
        width: 320px;
        height: 270px;
    }

    .explore_div_consume::after {
        width: 70px;
        height: 70px;
    }

    .explore_div_consume::after {
        font-size: 35px;
    }

    .explore_div_consume h2 {
        font-size: 16px;
    }

    .explore_div_consume p {
        font-size: 12px;
    }

    .tit_partner_say p {
        font-size: 28px;
    }

    .title_explore_2nd>p {
        font-size: 28px;
        margin: 90px 0 50px 0;
    }

    /* .data_of_circl p{font-size: 20px;} */
    .data_of_circl {
        width: 350PX;
    }

    .data_of_1 {
        /* bottom: -30%; */
    }

    .data_of_2 {
        bottom: -0%;
    }

    .data_of_3 {
        /* bottom: 5%; */
    }

    .data_of_4 {
        top: 35%;
    }

    .title_explore {
        max-width: 80%;
    }

    /* .PartnerWithUs_bli_title p,.explore_tit{font-size: 30px;} */
    .title_explore {
        width: 70%;
    }

    .circle_div.style p::after {
        width: 25px;
        height: 13px;
        bottom: -20px;
    }

    /* .circle_div p{font-size: 12px;} */
    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 22px;
    }

    .emm_pargraph p {
        font-size: 30px;
    }

    .text_emm_main_ {
        font-size: 40px;
    }

    .emm_text .p_embedded_ {
        font-size: 25px;
    }

    .NIYOGINfeed_imag_and_slider_div img {
        margin-bottom: -1%;
    }

    .blk_img img {
        height: 100px;
    }

    .circle_data_cont {
        margin: 70px auto 200px auto;
    }

    .circle_inside_three_container {
        width: 82%;
    }

    .p_tabs_ {
        margin: 0;
        word-break: break-all;
    }

    .title_and_p_leader h3 {
        font-size: 24px;
    }

    .p_tabs_ p,
    .title_and_p_leader p {
        font-size: 14px;
    }

    .leader_team_box_con .MuiButtonBase-root {
        /* font-size: 22px; */
        border-radius: 80px 20px 20px 20px;
        width: 230px;
        height: 180px;
    }

    .menu_cont>ul>li a {
        font-size: 13px;
    }

    .header_btn ul li a {
        font-size: 11px;
    }

    .text_tit_p_abt {
        top: 18%;
    }

    .abt_text_tit_p p {
        font-size: 15px;
    }

    .abt_text_tit_p h2 {
        font-size: 55px;
    }

    .text_tit_p_abt h2 {
        font-size: 32px;
    }

    /* .text_tit_p_abt p{font-size:16px ;} */
    .distribution_div_menu_list {
        top: 270px;
    }
}

@media screen and (max-width: 850px) {
    .shape_vector_divs {
        width: 300px;
    }

    .model_of_career .call_back_btn,
    .file_upload_div .input_style_cl h1,
    .file_upload_div .input_style_cl .btn_file_upload {
        font-size: 12px;
    }

    .model_of_career .title_model_carrer {
        font-size: 20px;
    }

    .tab_of_mobile_menu,
    .tab_of_mobile_menu .MuiCollapse-entered {
        width: 100% !important;
    }

    .header_menu_div .level_two_menu li {
        width: 100% !important;
        padding: 0;
    }

    .careers_tabs_main .p_tabs_ {
        height: 300px;
        aspect-ratio: unset;
    }
    .social_logo_cont a{margin:0 8px;}
    .careers_tabs_main .data_contain_tabs {
        padding: 30px;
        border-radius: 90px 20px 20px 20px;
    }

    .careear_btn_div button {
        font-size: 12px;
        margin: 20px auto 0 auto;
    }

    .level_4_menu {
        position: unset;
    }

    .level_4_menu>li {
        display: block;
    }

    .level_two_menu>li.level_three_menu .mobile_last_tab.Mui-expanded::after {
        transform: rotate(90deg);
    }

    .level_two_menu>li.level_three_menu {
        flex-direction: column;
        background-color: transparent;
        padding: 0;
    }

    .tab_of_mobile_menu .level_two_menu {
        display: flex;
        position: unset;
        flex-direction: column;
        width: 250px;
        margin: 0 auto;
        align-items: center;
    }

    .tab_of_mobile_menu .level_two_menu a {
        font-size: 15px;
        color: white;
        text-decoration: none;
        text-transform: capitalize;
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 15px 20px;
        display: block;
    }

    #panel1-content {
        padding: 0;
    }

    .level_two_menu>li.level_three_menu .mobile_last_tab>div>a {
        padding: 0;
    }

    .level_two_menu>li.level_three_menu::after {
        display: none;
    }

    .level_two_menu>li.level_three_menu .mobile_last_tab::after {
        position: absolute;
        content: "";
        background-image: url("../images//white_arr_menu.png");
        background-repeat: no-repeat;
        background-size: 100%;
        right: 20px;
        width: 10px;
        height: 20px;
        margin: auto;
        top: 0;
        bottom: 0;
    }

    .level_three_menu .tab_of_mobile_menu {}

    .level_two_menu>li.level_three_menu .mobile_last_tab {
        width: 100%;
        min-height: auto;
        background-color: #0091FF;
        align-items: flex-start;
        padding: 15px 20px;
        position: relative;
    }

    .level_two_menu>li.level_three_menu .mobile_last_tab>div {
        margin: 0;
    }

    .header_menu_div li:nth-child(2) {
        width: 100% !important;
    }

    .tabs_data_leader>div {
        overflow: auto;
    }

    .container_of_peop_data {
        width: 800px;
        overflow-x: auto;
    }

    .name_title_of_people h2 {
        font-size: 17px;
    }

    .name_title_of_people p {
        font-size: 11px;
    }

    .peopele_data_with_img {
        width: 30%;
    }

    .distribution_img {
        flex-wrap: wrap;
    }

    .image_distribution {
        width: 80%;
    }

    .Dis_main_title p {
        text-align: center;
    }

    .Dis_main_title {
        width: 100%;
    }

    .InvestorNewTabs_cont {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .InvestorNewTabs_cont .p_tabs_ {
        width: 50%;
    }

    .InvestorNewTabs_cont .acord_cont {
        width: 100%;
    }

    .InvestorLightBlue_main_section,
    .InvestorDarkBlue_main_p_sec {
        padding: 30px 30px 50px 30px;
    }

    .InvestorDarkBlue_main_p_sec p::after,
    .InvestorLightBlue_main_section p::after {
        width: 20%;
        min-width: 100px;
    }

    .investorValue_paragaph p {
        font-size: 17px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 16px;
    }

    .main_shape_titleInvestor {
        font-size: 25px;
    }

    .Investor_titleClass h2 {
        font-size: 26px;
    }

    .number_div {
        font-size: 20px;
        bottom: 10px;
    }

    .investor_fourh_shapes_div {
        flex-direction: column;
        gap: 80px;
        width: 75%;
    }

    .InvestorBanner_main_cont {
        flex-direction: column;
    }

    .Investor_ban_title,
    .Investor_rel_banner_div {
        width: 100%;
    }

    .OurOfferSection_main_cont {
        margin: 0 auto 50px auto;
    }

    .OurDistributionPlatform_p p {
        margin: 30px auto;
    }

    .distrib_class .silderr_partner_logo {
        margin: 30px auto 30px auto;
    }

    .collabra_opp_div h2 {
        margin: 50px 0 0 0;
    }

    .distribution_div_menu_list ul li {
        width: 100%;
        border-bottom: 1px solid white;
        border-left: 0;
        border-right: 0;
        padding: 20px;
        font-size: 15px;
        gap: 0;
    }

    .benifits_points_div ul li {
        font-size: 23px;
    }

    .distribution_div_menu_list ul {
        margin: 0 20px;
        gap: 0;
        overflow: hidden;
    }

    /* .shape_vector_divs:nth-child(3) .distribution_div_menu_list,.distribution_div_menu_list{right: unset ;left: unset;} */
    .distribution_div_menu_list {
        width: 60vw;
        top: 350px;
    }

    .distribution_div_menu_list.pop_mod_2 {
        top: 710px;
    }

    .distribution_div_menu_list.pop_mod_3 {
        top: 1050px;
    }

    .our_offer_divs {
        flex-direction: column;
        margin: 20px auto 50px auto;
    }

    .shape_vector_divs {
        width: 350px;
    }

    .titl_section_ouroff p {
        font-size: 20px;
        margin: 50px auto;
    }

    /* .after_ouroffer_p p,.para_section_ouroffer p{font-size: 14px;} */
    .OurOffer_pagraph p {
        font-size: 25px;
    }

    .distribution_ban_text h2 {
        font-size: 40px;
        text-align: center;
    }

    .distribution_ban_text p {
        font-size: 17px;
        text-align: center;
    }

    .distribution_ban_text {
        width: 80%;
    }

    .explore_div_for_data {
        margin: 0;
    }

    .div_2_ep .explore_div_for_data:nth-child(1) {
        background-image: url("../images//vector_img.png");
    }

    .div_2_ep .explore_div_for_data:nth-child(2) {
        background-image: url("../images/light_bl_2_vec.png");
    }

    .div_2_ep .explore_div_for_data:nth-child(1) .explore_div_consume::after {
        border: 12px solid #0069B5;
    }

    .div_2_ep .explore_div_for_data:nth-child(2) .explore_div_consume::after {
        border: 12px solid #6D92F0;
    }

    .div_expo {
        flex-direction: column;
        margin: 60px 0;
    }

    /* .CareerBanner_text p{font-size: 50px;} */
    .career_vac_tit h2 {
        font-size: 27px;
    }

    .titel_table p {
        font-size: 19px;
    }

    .postion_svg {
        bottom: -230px;
    }

    .form_container_div .verify_btn {
        width: fit-content;
    }

    .Collabrate_title p {
        font-size: 32px;
    }

    .tab_class_conta::after,
    .tab_class_conta::before {
        width: 20px;
        height: 20px;
    }

    .tab_class_conta::after {
        right: -30px;
    }

    .tab_class_conta::before {
        left: -30px;
    }

    .div_on_hover {
        width: 50vw;
        left: -100%;
    }

    .text_of_part_say li>p {
        font-size: 20px;
    }

    .image_of_part_and_txt {
        padding: 20px 0 50px 0;
    }

    .imag_vec_partner_say {
        width: 80%;
    }

    .text_of_part_say {
        width: 80%;
    }

    .explore_div_for_data:nth-child(3) .explore_div_consume>div,
    .explore_div_for_data:nth-child(1) .explore_div_consume>div {
        padding-top: 20px;
    }

    .explore_div_for_data:nth-child(4) .explore_div_consume>div,
    .explore_div_for_data:nth-child(2) .explore_div_consume>div {
        padding-top: 10px;
    }

    .explore_div_for_data {
        width: 300px;
    }

    .explore_div_consume {
        width: 290px;
        height: 230px;
    }

    .explore_div_consume::after {
        width: 50px;
        height: 50px;
        border-width: 10px !important;
    }

    .explore_div_consume::after {
        font-size: 31px;
    }

    .explore_div_consume h2 {
        font-size: 15px;
    }

    .explore_div_consume p {
        font-size: 12px;
    }

    .tit_partner_say p {
        font-size: 23px;
    }

    .title_explore_2nd>p {
        font-size: 23px;
        margin: 30px 0;
    }

    .title_explore {
        margin: -30px auto 0 auto;
    }

    /* .PartnerWithUs_bli_title p, .explore_tit{font-size: 13px;} */
    .tab_class_conta button {
        font-size: 12px;
    }

    .tab_class_conta>div>div {
        width: 1000px;
    }

    .circle_div p {
        font-size: 13px;
    }

    .emm_text .p_embedded_ {
        font-size: 23px;
        width: 100%;
        margin: 50px 0px 50px 0px;
    }

    .InvestorRetailsTabs_main_container {
        width: 80%;
    }

    .Embedded_with_image img {
        width: 100%;
    }

    .text_emm_main_ {
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .Embedded_with_image {
        flex-direction: column;
        gap: 50px;
    }

    .About_circle_main_div {
        margin: 80px 0;
    }

    .title_and_p_leader h3 {
        font-size: 20px;
    }

    .main_leadership_title h1 {
        font-size: 27px;
    }

    .text_tit_p_abt {
        top: 25%;
    }

    .header_menu_div li {
        width: fit-content !important;
    }

    .header_menu_div li>div {
        padding: 10px 0;
    }

    .header_menu_div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .mob_menu .head_mob_1::before {
        content: "";
        background-image: url("../images//header_arrow.png");
        background-position: center;
        background-repeat: no-repeat;
        height: 20px;
        /* width: 100%; */
        color: #24A0D0;
        bottom: -3px;
        margin: 0 auto;
        left: 0;
        right: 0;
        position: absolute;
    }

    .mob_menu .head_mob_1::after {
        content: "";
        /* width: 100%; */
        height: 2px;
        color: #24A0D0;
        bottom: 10px;
        margin: 0 auto;
        left: 0;
        right: 0;
        position: absolute;
        background-color: #24A0D0;
    }

    .circ_abt_img_txt {
        width: 70%;
    }

    .leader_team_box_con .MuiButtonBase-root {
        font-size: 18px;
    }

    .img_and_tex_connt_abt:nth-child(1) {
        margin: 0 0 0 0;
    }

    .img_and_tex_connt_abt:nth-child(2) {
        margin: -5% 0 0 0;
    }

    .img_and_tex_connt_abt:nth-child(3) {
        margin: -5% 0 0 0;
    }

    .img_and_tex_connt_abt:nth-child(4) {
        margin: -5% 0 0 0;
    }

    .blk_img img {
        height: 190px;
    }

    .img_abt_vec img {
        width: 100%;
    }

    .image_txt_div {
        flex-wrap: wrap;
    }

    .img_abt_vec {
        width: 100%;
    }

    .form_container_div .input_style_cl label {
        font-size: 15px;
    }

    .abt_text_tit_p {
        width: 80%;
        margin: 50px auto;
    }

    .image_txt_div:nth-child(1) {
        margin-bottom: 0;
    }

    .text_tit_p_abt h2 {
        font-size: 28px;
        margin: 0 0 5px 0;
    }

    .svg_div_footer {
        bottom: 30%;
    }

    .circle_data_cont {
        margin: 150px auto;
    }

    .svg_div {
        bottom: 30%;
        left: 0;
        right: 0;
        z-index: 2;
    }

    .svg_div_our_subsidiaries {
        bottom: 20%;
    }

    .circle_div_with_img {
        width: 40%;
    }

    .slider_part_class .slick-next:before {
        width: 26px;
        height: 26px;
    }

    .slider_part_class .slick-prev:before {
        width: 26px;
        height: 26px;
    }

    .circle_inside_three_container {
        flex-direction: column;
        padding: 0;
    }

    .circle_div_with_img:nth-child(1) {
        margin: 0 0 -5% 0;
    }

    .circle_div_with_img:nth-child(3) {
        margin: -5% 0 0 0;
    }

    .circle_numbers {
        font-size: 39px;
    }

    .circle_title {
        font-size: 17px;
    }

    .niyo_text .niyo_text_p_tit {
        font-size: 25px;
    }

    .niyo_news_all_img {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .niyo_news_img_div {
        width: 70%;
    }

    .header_main_cont_div {
        padding: 20px;
        width: calc(100% - 40px);
    }

    .title_Oursubsidiaries {
        font-size: 30px;
    }

    .Oursubsidiaries_blocks {
        justify-content: center;
        gap: 30px;
        margin: 50px auto;
    }

    .block_data_cont_Oursubsidiaries {
        width: 45%;
    }

    .text_banner {
        /* top: 45%; */
        left: 0%;
        right: 0%;
        margin: auto;
        width: fit-content;

    }

    .first_txt {
        font-size: 43px
    }

    .sec_txt {
        font-size: 50px;
    }

    .desk_banner {
        display: none;
    }

    .mob_banner {
        display: block;
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .distribution_div_menu_list ul li:nth-child(4) {
        border-bottom: 1px solid white;
    }

    .distribution_div_menu_list ul li:nth-child(8) {
        border-bottom: 1px solid white;
    }

    .distribution_div_menu_list ul li:nth-child(12) {
        border-bottom: 1px solid white;
    }

    .distribution_div_menu_list ul li:nth-child(16) {
        border-bottom: 1px solid white;
    }

    .mobile_menu_div {
        display: block;
    }

    .menu_cont,
    .header_btn {
        display: none;
    }

    .pragram_two_div p {
        color: #000;
        font-family: Avenir;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .block_data_cont {
        width: 100%;
    }

    .leding_and_disti_blocks {
        flex-wrap: wrap;
        gap: 50px;
        width: 65%;
    }

    .blk_tit {
        font-size: 17px;
    }

    .blk_readmore_ {
        font-size: 17px;
    }

    .part_title {
        font-size: 28px;
    }

    .svg_div_our_subsidiaries_2 {
        top: 2%;
        bottom: unset;
        display: block;
    }

    .circ_abt_img_txt {
        flex-direction: column;
    }

    .img_and_tex_connt_abt {
        width: 70%;
    }

    .text_of_part_say ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .distribution_img {
        margin-bottom: 0;
    }

    .Tab_2_acc .otp_divs {
        padding: 20px 0;
    }

    .AccordianOurOffer_main_cont {
        margin-bottom: 10px;
    }

    .BenefitsOfBecoming_title {
        margin: 50px auto;
    }
}

@media screen and (max-width: 750px) {
    .data_of_circl {
        width: 300px;
    }

    .footer_sec_div li a b,
    .footer_sec_div b {
        font-size: 15px;
        line-height: 40px;
    }

    .benifits_points_div ul li {
        font-size: 20px;
    }

    .OurDistributionPlatform_title h2,
    .BenefitsOfBecoming_title p,
    .distrib_class .part_title {
        font-size: 18px;
    }

    .distribution_div_menu_list ul {
        padding: 25px 0;
    }

    .titl_section_ouroff p {
        font-size: 18px;
    }

    /* .after_ouroffer_p p,.para_section_ouroffer p{font-size: 13px;} */
    .OurOffer_pagraph p {
        font-size: 24px;
    }

    .distribution_ban_text h2 {
        font-size: 35px;
        margin-top: 0;
    }

    .distribution_ban_text p {
        font-size: 15px;
    }

    .careers_tabs_main .leader_team_box_con .tab_class>div>div {
        min-width: unset;
    }

    .careers_tabs_main .leader_team_box_con .MuiButtonBase-root {
        width: 40%;
        aspect-ratio: 1;
        height: auto;
        max-width: 40%;
    }

    .four_exlpore_divs {
        padding: 0 0 50px 0;
    }

    .CareerBanner_text p {
        font-size: 43px;
        margin-bottom: 60px;
    }

    .career_vac_tit h2 {
        font-size: 22px;
    }

    .titel_table p {
        font-size: 17px;
    }

    .postion_svg {
        bottom: -20px;
    }

    .form_container_div .phone_div,
    .form_container_div .email_div {
        width: 100%;
    }

    .form_container_div .verify_btn {
        font-size: 13px;
        width: fit-content;
    }

    /* .Collabrate_title p{font-size: 42px;} */
    .title_explore_2nd>p {
        margin: 30px 0;
    }

    .data_of_circl p {
        font-size: 17px;
    }

    .data_of_3 {
        bottom: 3%;
    }

    .data_of_5 {
        top: 30%;
    }

    .data_of_2 {
        bottom: -1%;
    }

    .data_of_1 {
        bottom: -20%;
    }

    .data_of_circl {
        padding: 0px 30px;
        border-radius: 90px 30px 30px 10px;
    }

    .PartnerWithUs_bli_title p,
    .explore_tit {
        font-size: 18px;
    }

    .circle_div.style p::after {
        width: 20px;
        height: 10px;
        bottom: -16px;
    }

    .circle_div p {
        font-size: 14px;
    }

    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 20px;
    }

    .emm_pargraph p {
        font-size: 25px;
    }

    .text_emm_main_ {
        font-size: 37px;
    }

    .emm_text .p_embedded_ {
        font-size: 23px;
        margin: 30px 0 50px 0;
    }

    .data_contain_tabs {
        width: 80%;
    }

    .div_img_p_cont {
        flex-wrap: wrap;
        justify-content: center;
    }

    .data_img {
        width: 50%;
    }

    .title_and_p_leader {
        width: 100%;
        padding: 50px 0 0 0;
    }

    .main_leadership_title h1 {
        font-size: 25px;
    }

    .image_txt_div_container {
        gap: 50px;
    }

    .abt_text_tit_p {
        width: 80%;
        margin: 0px auto;
    }

    .ls_footer_con a,
    .footer_sec_div li a,
    .footer_sec_div {
        font-size: 13px;
        line-height: 30px;
    }

    .social_logo_cont img {
        width: 22px;
    }

    .social_logo_cont p {
        font-size: 15px;
    }

    .feed_tit {
        font-size: 15px;
    }

    .feed_paragaph {
        font-size: 12px;
    }

    .feed_btn {
        font-size: 17px;
    }

    .block_feed_div {
        min-height: 270px;
        max-height: 270px;
    }

    .block_slider_feed {
        width: 70%;
        bottom: -20%;
    }

    .slider_part_class .slick-next:before {
        right: -70%;
    }

    .slider_part_class .slick-prev:before {
        left: -70%;
    }

    .part_title {
        font-size: 27px;
    }

    .circle_data_cont {
        margin: 80px auto 90px auto;
    }

    .circle_inside_three_container {
        padding: 0;
    }

    .circle_numbers {
        font-size: 30px;
    }

    .circle_title {
        font-size: 16px;
    }

    .niyo_text .niyo_text_p_tit {
        font-size: 32px;
    }

    .niyo_news_img_div {
        width: 80%;
    }

    .block_data_cont_Oursubsidiaries {
        border-radius: 50px 10px 10px 10px;
    }

    .blk_img_Oursubsidiaries img {
        height: 60px;
        width: 60px;
    }

    .blk_tit_Oursubsidiaries {
        font-size: 20px;
        padding: 8px 0 0 0;
    }

    .blk_img_Oursubsidiaries {
        padding: 10px;
    }

    .blk_readmore_Oursubsidiaries {
        font-size: 17px;
        padding: 10px 17px;
    }

    .text_banner {
        /* top: 45%; */
        left: 0%;
        right: 0%;
        margin: auto;
    }

    .first_txt {
        font-size: 32px
    }

    .sec_txt {
        font-size: 38px;
    }

    .ls_footer_con a p {
        margin-bottom: 10px;
    }

    .social_logo_cont p {
        font-size: 15px;
    }

    .footer_sec_cont {
        flex-direction: column-reverse;
        text-align: center;
        gap: 25px;
        padding: 230px 30px 20px 30px;
    }

    .first_foot {
        width: 100%;
    }

    .sec_foot {
        width: 65%;
    }

    .sec_foot ul {
        padding-left: 30px;
    }

    .third_foot {
        width: 35%;
    }

    .ls_footer_con {
        flex-direction: column;
        padding: 0px 30px 30px 30px;
    }

    .twodiv_combine {
        width: 80%;
        text-align: left;
        margin: 0 auto;
        justify-content: space-between;
    }

    .leding_and_disti_blocks {
        width: 70%;
    }

    .NIYOGINfeed_imag_and_slider_div img {
        margin-bottom: -1%;
    }

    .text_tit_p_abt p {
        font-size: 14px;
    }

}

@media screen and (max-width: 650px) {
    .distribution_div_menu_list ul li {
        min-height: auto;
    }

    .mini_tabs button {
        font-size: 11px;
        margin: 0 10px;
    }

    .tab_class_conta button {
        height: 115px;
    }

    .InvestorNewTabs_cont .acordian_ivest p {
        font-size: 16px;
    }

    .tab_mini_content .dowlod_block p {
        font-size: 10px;
    }

    .InvestorNewTabs_cont .p_tabs_ h2 {
        font-size: 24px;
    }

    .InvestorNewTabs_cont .p_tabs_ p {
        font-size: 15px;
    }

    .InvestorNewTabs_cont .p_tabs_ {
        width: 100%;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        padding: 35px 0;
    }

    .investorValue_paragaph p {
        margin: 20px 0 35px 0;
    }

    .main_shape_titleInvestor {
        font-size: 20px;
    }

    .number_div {
        font-size: 18px;
    }

    .Investor_ban_title h2 {
        font-size: 35px;
    }

    .Tab_2_acc .box_otp_form {
        padding: 20px 20px 12px 20px;
    }

    .Tab_2_acc .otp_divs {
        padding: 10px 0 20px 0;
    }

    .box_otp_form>div {
        height: 20px;
    }

    .Tab_accord_form_main .btn_container button {
        font-size: 15px;
        padding: 15px 80px;
    }

    /* .Tab_2_acc .text_form_tit{font-size: 20px;} */
    .collabra_opp_div h2 {
        font-size: 27px;
    }

    .collabra_opp_div p {
        font-size: 19px;
    }

    .benifits_points_div ul li {
        font-size: 18px;
    }

    .titl_section_ouroff p {
        font-size: 17px;
    }

    /* .after_ouroffer_p p,.para_section_ouroffer p{font-size: 13px;} */
    .OurOffer_pagraph p {
        font-size: 23px;
    }

    .distribution_ban_text h2 {
        font-size: 28px;
    }

    .distribution_ban_text p {
        font-size: 14px;
    }

    .CareerBanner_text p {
        font-size: 38px;
    }

    .career_vac_tit h2 {
        font-size: 20px;
    }

    .titel_table p {
        font-size: 15px;
    }

    /* .Collabrate_title p{font-size: 38px;} */
    .data_of_circl p {
        font-size: 14px;
    }

    .data_of_circl {
        width: 250px;
    }

    .explore_div_for_data {
        margin: 0px 0;
    }

    .text_of_part_say li>p {
        font-size: 19px;
    }

    .div_on_hover {
        /* width: 210px;
        height: 180px; */
        left: -50%;
        top: 30%;
    }

    .form_container_div .form_first {
        max-width: 90% !important;
    }

    .form_container_div .input_style_cl input {
        height: 30px;
    }

    .form_container_div .input_style_cl>div>div {
        /* min-height: 65px; */
    }

    .accordin_click_class p {
        width: 70%;
        padding: 15px 40px;
    }

}

@media screen and (max-width: 550px) {
    .block_slider_feed {
        width: 70%;
        bottom: -30%;
    }

    .investorValue_paragaph p {
        font-size: 15px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 14px;
    }

    .Investor_titleClass h2 {
        font-size: 18px;
        width: 70%;
        margin: 0 auto 10px auto;
    }

    .main_shape_titleInvestor {
        font-size: 17px;
    }

    .cicle_icons {
        border-width: 6px;
    }

    .number_div {
        font-size: 15px;
        bottom: 12px;
    }

    .Investor_ban_title h2 {
        font-size: 28px;
    }

    .box_otp_form>div {
        height: 10px;
    }

    .Tab_2_acc .otp_divs {
        padding: 5px 0 15px 0;
    }

    .Tab_accord_form_main .input_style_cl>div>div {
        height: 35px;
        margin: 5px 0;
    }

    /* .Tab_accord_form_main .form_first p{font-size: 17px;} */
    .Tab_accord_form_main .input_style_cl label,
    .Tab_accord_form_main .form_first form input {
        font-size: 12px;
    }

    .Tab_accord_form_main .input_style_cl label {
        top: calc(45% - 12px);
    }

    .Tab_accord_form_main .btn_container button {
        font-size: 12px;
        padding: 10px 50px;
    }

    /* .Tab_2_acc .text_form_tit{font-size: 17px;margin-bottom: 5px;} */
    .Tab_2_acc .box_otp_form {
        padding: 20px 20px 8px 20px;
    }

    .collabra_opp_div h2 {
        font-size: 22px;
    }

    .collabra_opp_div p {
        font-size: 18px;
    }

    .Accordion_tabs_class {
        font-size: 14px;
    }

    .accordin_click_class p {
        font-size: 15px;
    }

    .ourDistribu_video iframe {
        border-radius: 30px;
    }

    .benifits_points_div ul li {
        font-size: 15px;
        margin: 5px 0;
    }

    .shape_vector_divs {
        width: 250px;
    }

    .shape_vector_divs .OurOffer_pagraph::before {
        bottom: -24px;
        width: 10%;
        aspect-ratio: 1/2;
    }

    .distribution_div_menu_list ul {
        padding: 0 20px;
    }

    .distribution_div_menu_list {
        border-radius: 30px;
        top: 250px;
    }

    .distribution_div_menu_list.pop_mod_3 {
        top: 750px;
    }

    .distribution_div_menu_list.pop_mod_2 {
        top: 500px;
    }

    .titl_section_ouroff p {
        font-size: 15px;
    }

    .OurDistributionPlatform_p p,
    .after_ouroffer_p p,
    .para_section_ouroffer p {
        font-size: 14px;
    }

    .OurOffer_pagraph p {
        font-size: 22px;
    }

    .distribution_ban_text h2 {
        font-size: 25px;
    }

    .distribution_ban_text p {
        font-size: 13px;
    }

    .CareerBanner_text p {
        font-size: 30px;
    }

    .career_vac_tit h2 {
        font-size: 15px;
    }

    .circ_abt_img_txt {
        width: 85%;
    }

    .titel_table p {
        font-size: 15px;
    }

    .postion_svg {
        bottom: 100px;
    }

    .Collabrate_title p {
        font-size: 35px;
    }

    .PartnerWithUs_bli_title p,
    .explore_tit {
        font-size: 15px;
    }

    .explore_div_consume::after {
        font-size: 21px;
    }

    .explore_div_consume h2 {
        font-size: 13px;
    }

    .explore_div_consume p {
        font-size: 11px;
    }

    .tit_partner_say p {
        font-size: 18px;
    }

    .title_explore_2nd>p {
        font-size: 18px;
        margin: 30px 0;
    }

    .data_of_circl {
        width: 180px;
    }

    .data_of_circl p {
        font-size: 12px;
    }

    .circle_classes1 {
        bottom: 10%;
    }

    .circle_classes5 {
        top: 10%;
    }

    .circle_div.style p::after {
        width: 15px;
        height: 9px;
        bottom: -12px;
    }

    .circle_div p {
        font-size: 8px;
    }

    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 15px;
    }

    .text_emm_main_ {
        font-size: 30px;
    }

    .emm_text .p_embedded_ {
        font-size: 22px;
    }

    .leader_team_box_con .MuiButtonBase-root {
        height: 150px;
    }

    .leader_team_box_con .tab_class>div>div {
        min-width: 700px;
        padding: 0 20px;
    }

    .p_tabs_ p,
    .title_and_p_leader p {
        font-size: 12px;
    }

    .text_tit_p_abt h2 {
        font-size: 25px;
    }

    .text_tit_p_abt p {
        font-size: 13px;
    }

    .twodiv_combine {
        text-align: center;
    }

    .silderr_partner_logo {
        margin: 50px auto;
    }

    .pragram_two_div {
        margin: 50px auto 0px auto;
    }

    .title_Oursubsidiaries {
        margin: 60px auto;
    }

    .leding_and_disti_blocks {
        margin: 50px auto;
        width: 60%;
    }

    .home_svg {
        bottom: 20%;
    }

    .ls_footer_con a,
    .footer_sec_div li a,
    .footer_sec_div {
        font-size: 12px;
    }

    .sec_foot,
    .third_foot {
        width: 45%;
    }

    /* .block_feed_div{min-height: 350px;max-height: 350px; } */
    .feed_tit {
        font-size: 14px;
    }

    .feed_paragaph {
        font-size: 11px;
    }

    .feed_btn {
        font-size: 15px;
    }

    .silderr_partner_logo {
        width: 80%;
    }

    .slider_part_class .slick-next:before {
        right: -40%;
    }

    .slider_part_class .slick-prev:before {
        left: -40%;
    }

    .part_title {
        font-size: 26px;
    }

    .niyo_news_all_img {
        gap: 10px;
    }

    .niyo_text .niyo_text_p_tit {
        font-size: 28px;
    }

    .niyo_news_img_div {
        width: 85%;
    }

    .title_Oursubsidiaries {
        font-size: 25px;
    }

    .Oursubsidiaries_blocks {
        gap: 20px;
        margin: 50px auto 0px auto;
    }

    .pragram_two_div p {
        color: #000;
        font-family: Avenir;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text_banner {
        /* top: 43%; */
        left: 0%;
        right: 0%;
        margin: auto;
    }

    .first_txt {
        font-size: 28px
    }

    .sec_txt {
        font-size: 31px;
    }

    .sec_foot ul {
        padding-left: 10px;
    }

    .footer_sec_cont {
        padding: 180px 10px 10px 10px;
    }

    .ls_footer_con {
        padding: 0px 10px 30px 10px;
    }

    .twodiv_combine {
        width: 95%;
    }

    .blk_img img {
        height: 130px;
    }

    .block_data_cont {
        border-radius: 70px 20px 20px 20px;
    }

    .NIYOGINfeed_imag_and_slider_div img {
        margin-bottom: 0%;
    }

    .OurDistributionPlatform_title h2,
    .BenefitsOfBecoming_title p,
    .distrib_class .part_title {
        font-size: 15px;
        width: 80%;
        margin: 0 auto;
    }

    .InvestorBanner_main_cont {
        padding: 30px;
    }
}

@media screen and (max-width: 470px) {
    .data_of_5 {
        left: 17%;
    }

    .titel_table.rows_tb p {
        font-size: 12px;
        text-align: left;
    }

    .titel_table div:nth-child(1) p {
        padding-left: 10px;
        text-align: left;
    }

    .careers_tabs_main .leader_team_box_con .MuiButtonBase-root {
        border-radius: 50px 10px 10px 10px;
        font-size: 15px;
    }

    .investor_fourh_shapes_div {
        margin: 50px auto;
    }

    .investorValue_paragaph p {
        font-size: 13px;
    }

    .InvestorDarkBlue_main_p_sec p,
    .InvestorLightBlue_main_section p {
        font-size: 11px;
    }

    .Investor_titleClass h2 {
        font-size: 15px;
    }

    .cicle_icons {
        width: 20px;
        top: -30px;
    }

    .cicle_icons img {
        width: calc(100% - 2px);
    }

    .number_div {
        font-size: 11px;
        bottom: 15px;
    }

    .main_shape_titleInvestor {
        font-size: 13px;
    }

    .Investor_ban_title h2 {
        font-size: 23px;
    }

    .collabra_opp_div h2 {
        font-size: 18px;
    }

    .collabra_opp_div p {
        font-size: 15px;
    }

    .Accordion_tabs_class {
        font-size: 12px;
    }

    .accordin_click_class p {
        font-size: 13px;
    }

    .benifits_points_div ul li {
        font-size: 13px;
    }

    .titl_section_ouroff p {
        font-size: 14px;
    }

    .OurDistributionPlatform_p p,
    .after_ouroffer_p p,
    .para_section_ouroffer p {
        font-size: 11px;
    }

    .OurOffer_pagraph p {
        font-size: 21px;
    }

    .distribution_ban_text h2 {
        font-size: 22px;
    }

    .distribution_ban_text p {
        font-size: 12px;
    }

    .CareerBanner_text p {
        font-size: 26px;
    }

    .career_vac_tit h2 {
        font-size: 14px;
    }

    .titel_table p {
        font-size: 14px;
    }

    .Collabrate_title p {
        font-size: 32px;
    }

    .div_on_hover {
        /* width: 290px;
        height: 230px; */
        top: 70%;
    }

    .text_of_part_say ul {
        align-items: start;
    }

    .circle_classes4 {
        top: 17%;
    }

    .circle_classes1 {
        bottom: 13%;
    }

    .circle_classes3 {
        bottom: 45%;
    }

    .circle_classes6 {
        top: 30%;
        width: 27%;
    }

    .circle_classes2 {
        bottom: 25%;
    }

    .EmbeddedLandingSolutions_title h2,
    .title_explore_2nd>p {
        font-size: 13px;
    }

    .emm_pargraph p {
        font-size: 20px;
    }

    .text_emm_main_ {
        font-size: 21px;
    }

    .emm_text .p_embedded_ {
        font-size: 20px;
    }

    .text_tit_p_abt {
        top: 16%;
    }

    .main_leadership_title h1 {
        font-size: 21px;
    }

    .text_tit_p_abt h2 {
        font-size: 20px;
    }

    .text_tit_p_abt p {
        font-size: 10px;
    }

    /* .block_feed_div a {
        padding: 40px 20px;
    } */

    .block_feed_div {
        min-height: 270px;
        max-height: 270px;
    }

    .feed_tit {
        font-size: 12px;
    }

    .feed_paragaph {
        font-size: 10px;
    }

    .feed_btn {
        font-size: 13px;
    }

    .slider_part_class .slick-next:before {
        right: -20%;
    }

    .slider_part_class .slick-prev:before {
        left: -20%;
    }

    .part_title {
        font-size: 24px;
    }

    .circle_data_cont {
        margin: 70px auto 80px auto;
    }

    .circle_inside_three_container {
        padding: 0;
    }

    .niyo_news_all_img {
        gap: 5px;
    }

    .niyo_text .niyo_text_p_tit {
        font-size: 24px;
    }

    .title_Oursubsidiaries {
        font-size: 23px;
    }

    .blk_img_Oursubsidiaries img {
        height: 50px;
        width: 50px;
    }

    .blk_tit_Oursubsidiaries {
        font-size: 14px;
        padding: 8px 0 0 0;
    }

    .blk_readmore_Oursubsidiaries {
        font-size: 9px;
        padding: 10px 17px;
    }

    .blk_tit {
        padding: auto;
        padding: 10px 0;
    }

    .blk_readmore_ {
        padding: 10px 20px;
    }

    .blk_img img {
        height: 100px;
    }
}


@media screen and (max-width: 400px) {
    .investor_fourh_shapes_div {
        width: 65%;
    }

    .main_shape_titleInvestor {
        font-size: 12px;
    }

    .number_div {
        font-size: 11px;
    }

    .InvestorBanner_main_cont {
        padding: 30px;
    }

    .Investor_ban_title h2 {
        font-size: 22px;
    }

    .collabra_opp_div h2 {
        font-size: 16px;
    }

    .collabra_opp_div p {
        font-size: 13px;
    }

    .Accordion_tabs_class {
        font-size: 11px;
    }

    .accordin_click_class p {
        font-size: 12px;
    }

    .benifits_points_div ul li {
        font-size: 11px;
    }

    .distribution_div_menu_list {
        font-size: 17px;
    }

    .distribution_ban_text h2 {
        font-size: 19px;
    }

    .distribution_ban_text p {
        font-size: 11px;
    }

    .CareerBanner_text p {
        font-size: 21px;
    }

    .career_vac_tit h2 {
        font-size: 12px;
    }

    .titel_table p {
        font-size: 10px;
    }

    .postion_svg {
        bottom: 200px;
    }

    .form_container_div .verify_btn {
        font-size: 8px;
        width: fit-content;
    }

    .Collabrate_title p {
        font-size: 29px;
    }

    .text_of_part_say li>p {
        font-size: 15px;
    }

    .PartnerWithUs_bli_title p,
    .explore_tit {
        font-size: 12px;
        padding: 15px 25px;
    }

    .explore_div_consume::after {
        font-size: 18px;
    }

    .explore_div_consume h2 {
        font-size: 11px;
    }

    .explore_div_consume p {
        font-size: 8px;
    }

    .blk_img img {
        height: 80px;
    }

    .block_feed_div {
        min-height: 230px;
        max-height: 230px;
    }

    .feed_tit {
        font-size: 8px;
    }

    .feed_paragaph {
        font-size: 8px;
    }

    .feed_btn {
        font-size: 11px;
    }

    .slider_part_class .slick-next:before {
        right: 0%;
    }

    .slider_part_class .slick-prev:before {
        left: 0%;
    }

    .part_title {
        font-size: 21px;
    }

    .circle_div_with_img {
        width: 60%;
    }

    .niyo_text .niyo_text_p_tit {
        font-size: 21px;
    }

    .title_Oursubsidiaries {
        font-size: 20px;
    }

    .blk_tit {
        font-size: 17px;
    }

    .blk_readmore_ {
        font-size: 12px;
    }

    .pragram_two_div p {
        color: #000;
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    /* .blk_img img {height: 130px;} */
}